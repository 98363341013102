import React from "react";
import healthbanner from "../img/healthbanner.webp";
import Button from 'react-bootstrap/Button';
import Introduction from "../img/yogagirl.webp";
import ClientStory from "../img/helthyleve.webp";
import introctabanner from "../img/introctabannersec.webp";
import opentextlogo from "../img/opentext.webp";
import Microsoftlogo from "../img/Microsoft.webp";
import boomilogo from "../img/boomi.webp";
import qadlogo from "../img/solace.webp";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


function healthpage() {
    return ( 
        <div className="managedsevice" id="managedsevice96">
            <div className="bannerhome"  style={{ backgroundImage: `url(${healthbanner})` }}>
                <div className="container">
                    <div className="bannersection">
                        <div className="bannersec_conetct">
                            <h1>Health</h1>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="click_down_menu">
                <Button variant="link" href="#Introduction98" className="fixbuttons introduction">Introduction</Button>
                <Button variant="link" href="#service28" className="fixbuttons service_highlight">Service Highlight</Button>
                <Button variant="link" href="#benefits28" className="fixbuttons benefits">Partners</Button>
                <Button variant="link" href="#clintsto32" className="fixbuttons client_story">Client Story</Button>
            </div>  */}
            <div className="container">
                <div className="img-with-text" id="Introduction98">
                    <div className="row">
                        <div className="col imgtextcont">
                            <img src={Introduction} className="imgwithtext" alt="sitelogo"  width="600" />
                        </div>
                        <div className="col abouttext">
                            <div className="site-text-content">  
                                <h1>Looking to outsource all your IT operations for peace of mind?</h1>
                                <span><h6>Managed Services takes the pain and worry away from dealing
                                     with the myriad of IT issues that get in the way of business stability and agility.</h6></span>
                                <span><h6>From resolving small and large issues to providing strategic IT services, we can help.</h6></span>
                                <span><h6>Whether you’re a small, medium or large enterprise, we offer reliable,
                                     end-to-end managed services—delivered as promised.</h6></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service_highlights" id="service28">
                    <div className="row">
                    <div className="col-4">
                            <h1 className="featured_hadding">Service Highlights</h1>
                            <h3 className='featurde_sulog'>Drive success and growth with end-to-end IT support services</h3>
                        </div>
                        <div className="col-8">
                            <Carousel responsive={responsive}>
                                <div className="crosalbox">
                                    <h2>Sovereign capabilities</h2>
                                    <h5>Publicly listed on the ASX and a trusted sovereign partner.</h5>
                                </div>
                                <div className="crosalbox">
                                    <h2>Security cleared</h2>
                                    <h5>Benefit from extensive on-shore capabilities with over 200 security-cleared staff.</h5>
                                </div>
                                <div className="crosalbox">
                                    <h2>Industry experience</h2>
                                    <h5>Engagement across 9 of the 16 services and groups within Defence.</h5>
                                </div>
                                <div className="crosalbox">
                                    <h2>Enterprise governance</h2>
                                    <h5>Evidence-based advice to enable or improve enterprise governance.</h5>
                                </div>
                                <div className="crosalbox">
                                    <h2>Change and adoption</h2>
                                    <h5>Achieve your strategic objectives by preparing your people for a new reality.</h5>
                                </div>
                            </Carousel>
                        </div>    
                    </div>    
                </div>
            </div>
            <div className="clints_logo" id="benefits28">
                <div className="container">
                    <h1 className="featured_hadding">Partners</h1>
                    <div className="row">
                        <div className="col">
                            <div><img src={Microsoftlogo} className="" alt="sitelogo"  width="200px" /></div>   
                        </div>
                        <div className="col">
                            <div><img src={boomilogo} className="" alt="sitelogo"  width="200px" /></div>
                        </div>
                        <div className="col">
                            <div><img src={opentextlogo} className="" alt="sitelogo"  width="200px" /></div>
                        </div>
                        <div className="col">
                            <div><img src={qadlogo} className="" alt="sitelogo"  width="150px" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="img-with-text client_story" id="clintsto32">
                    <div className="row">
                        <div className="col abouttext">
                            <div className="site-text-content">
                                <h5>Press release</h5>
                                <h1>Client Story</h1>
                                <span><h6>Enabling people with Diabetes to gain better access to services,<br/> support and diabetes’ products with modern and <br/>streamlined processes.</h6></span>
                                    <Button variant="btn btn-outline-primary sitebuttonrepet">Read Client Story</Button>{' '}
                            </div>
                        </div>
                        <div className="col imgtextcont">
                            <img src={ClientStory} className="imgwithtext" alt="sitelogo"  width="600" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cta-section" style={{ backgroundImage: `url(${introctabanner})` }}>
            <div className="ctabackground">
              <h1>Looking for a step-change in your <br/> business’ technology capabilities?</h1>
              <h4>We’ll lead you there.</h4>
              <Button variant="btn btn-outline-primary learn">LEARN MORE..</Button>{' '}
            </div>
          </div>  
        </div>
    );
 }

export default healthpage;