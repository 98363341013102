import React from 'react';
import '../css/App.css';
import sitelogo from '../img/sitmenlogo.webp';
import fastsubmenu from '../img/fastsubmenu.webp';
import secsubmenu from '../img/secsubmenu.webp';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
// @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;500&display=swap');
// import toggleicon from '../img/togglicon.svg'


function Siteheader() {
  return (  
    <div className="App hdmainclass">
      <header className="edimark_hadder container">
        <div className="site_main_hadder">
           <div className="logo_menu d-flex justify-content-between container">
              <div className="site_logo">
              <Link to="/">
                <img src={sitelogo} className="sitlogo" alt="sitelogo"  width="250" />
                </Link>
              </div>
              <div className="navbarmenu">
                <div className="subnav">
                  {/* <div className="subnavbtn" to="/">Service</div> */}
                  <Link to="#" className="subnavbtn">Service</Link>
                  <div className="subnav-content" style={{width: "45%"}}>
                    <div className="subservice d-flex">
                      <div className="subserviceone" style={{width: "45%"}}>
                        <ul>
                          {/* <li className="submenulist"><Link to="/advisory-consulting" >Advisory & Consulting</Link></li>
                          <li className="submenulist"><Link to="#" >Business Applications</Link></li>
                          <li className="submenulist"><Link to="#" >Cloud Services</Link></li> */}
                          <li className="submenulist"><Link to="/data-integration" >Integration</Link></li>
                          <li className="submenulist"><Link to="/managed" >Managed Services</Link></li>
                          {/* <li className="submenulist"><Link to="/">Logistics</Link></li> */}
                        </ul>
                      </div>
                      <div className="subservicetwo" style={{width: "55.1%"}}>
                        <ul>
                          <li className="secsubmenus">Featured Client Stories</li>
                          <li><img src={fastsubmenu} className="sitlogo" alt="sitelogo"  width="250" /></li>
                          <li className="secsubmenus">TRAFFIX Boomi cloud migration</li>
                          <li><img src={secsubmenu} className="sitlogo" alt="sitelogo"  width="250" /></li>
                          <li className="secsubmenus">Data2Logistics Sterling Integrator Upgrade</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="subnav">
                  {/* <div className="subnavbtn" to="/">Service</div> */}
                  <Link to="#" className="subnavbtn">Industries</Link>
                  <div className="subnav-content" style={{width: "45%"}}>
                    <div className="subservice d-flex">
                      <div className="subserviceone" style={{width: "45%"}}>
                        <ul>
                        <li className="submenulist"><Link to="/health">Health</Link></li>
                          <li className="submenulist"><Link to="/retail">Retail</Link></li>
                          {/* <li className="submenulist"><Link to="/">Logistics</Link></li> */}
                        </ul>
                      </div>
                      <div className="subservicetwo" style={{width: "55.1%"}}>
                        <ul>
                        <li className="secsubmenus">Featured Client Stories</li>
                          <li><img src={fastsubmenu} className="sitlogo" alt="sitelogo"  width="250" /></li>
                          <li className="secsubmenus">TRAFFIX Boomi cloud migration</li>
                          <li><img src={secsubmenu} className="sitlogo" alt="sitelogo"  width="250" /></li>
                          <li className="secsubmenus">Data2Logistics Sterling Integrator Upgrade</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="subnav">
                  <Link to="#" className="subnavbtn">Industries</Link>
                  <div className="subnav-content Industries" style={{width: "65%"}}>
                  <div className="subservice d-flex">
                      <div className="subserviceone" style={{width: "30%"}}>
                        <ul>
                          <li className="submenulist"><Link to="#">Health</Link></li>
                          <li className="submenulist"><Link to="#">Retail</Link></li>
                          <li className="submenulist"><Link to="#">Partners</Link></li>
                        </ul>
                      </div>
                      <div className="subserviceone" style={{width: "30%"}}>
                        <ul>
                          <li className="submenulist"><Link to="#" >Advisory & Consulting</Link></li>
                          <li className="submenulist"><Link to="#" >Business Applications</Link></li>
                          <li className="submenulist"><Link to="#" >Cloud Services</Link></li>
                          <li className="submenulist"><Link to="#" >Data & Integration</Link></li>
                          <li className="submenulist"><Link to="#" >Managed Services</Link></li>
                        </ul>
                      </div>
                      <div className="subservicetwo" style={{width: "40%"}}>
                        <ul>
                          <li className="secsubmenus">Featured Client Stories</li>
                          <li><img src={fastsubmenu} className="sitlogo" alt="sitelogo"  width="250" /></li>
                          <li className="secsubmenus">WLTH Boomi Data Integration</li>
                          <li><img src={secsubmenu} className="sitlogo" alt="sitelogo"  width="250" /></li>
                          <li className="secsubmenus">Lion Webmethods Modernisation</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="subnav"> */}
                  {/* <button className="subnavbtn">Partners <i className="fa fa-caret-down"></i></button> */}
                  {/* <Link to="/Partners" className="subnavbtn">Partners</Link>
                </div>
                <div className="subnav"> */}
                  {/* <button className="subnavbtn">Partners <i className="fa fa-caret-down"></i></button> */}
                  {/* <Link to="/client-story" className="subnavbtn">Client Story</Link>
                </div> */}
                <div className="subnav">
                  {/* <button className="subnavbtn">About us <i className="fa fa-caret-down"></i></button> */}
                  <Link to="/aboutus" className="subnavbtn">About us</Link>
                  <div className="subnav-content aboutus" style={{width: "20%"}}>
                  <div className="subserviceone" >
                        <ul>
                          <li className="submenulist"><Link to="/aboutus" >Our Company</Link></li>
                          {/* <li className="submenulist"><Link to="/investors">Investors</Link></li> */}
                          <li className="submenulist"><Link to="/get-in-touch" >Get In Touch</Link></li>
                        </ul>
                      </div>
                  </div>
                </div>
              </div>
              {/* mobhil view  */}
              <div className="mobhil_view">
              <Navbar expand="lg">
                <Container>
                  <Navbar.Brand href="#home"></Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                      <NavDropdown title="Service" id="basic-nav-dropdown">
                        <NavDropdown.Item ><Link to={"/data-integration"} >Integration</Link></NavDropdown.Item>
                        <NavDropdown.Item ><Link to={"/managed"} >Managed Services</Link></NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Industries" id="basic-nav-dropdown">
                        <NavDropdown.Item ><Link to={"/health"}>Health</Link></NavDropdown.Item>
                        <NavDropdown.Item ><Link to={"/retail"}>Retail</Link></NavDropdown.Item>
                      </NavDropdown>
                      {/* <Nav.Link><Link to="/Partners" >Partners</Link></Nav.Link> */}
                      {/* <Nav.Link ><Link to="/client-story" >Client Story</Link></Nav.Link> */}
                      <NavDropdown title="About Us" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1"><Link to="/aboutus" >Our Company</Link></NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3"><Link to="/get-in-touch" >Get In Touch</Link></NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
              </div>
           </div>
        </div>
        <script src="https://unpkg.com/react/umd/react.production.min.js" crossOrigin={"anonymous"}></script>

        <script
          src="https://unpkg.com/react-dom/umd/react-dom.production.min.js"
          crossOrigin={"anonymous"}></script>

        <script
          src="https://unpkg.com/react-bootstrap@next/dist/react-bootstrap.min.js"
          crossOrigin={"anonymous"}></script>
      </header>
       
    </div>
  );
}

export default Siteheader;
