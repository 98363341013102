// import logo from './logo.svg';
import React from 'react';
import Siteheader from './component/header';
import Sitefooter from './component/footer';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter } from "react-router-dom";
import Navbar from './navbar';


function App() {


  return (
    <BrowserRouter>
      <Siteheader />
      <Navbar />
      <Sitefooter />
    </BrowserRouter>
  );
}

export default App;
