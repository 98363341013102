import React, { useRef,  } from "react";
import contectpageimg from "../img/contectpage.webp"
// import Sydneyicon from "../img/Sydney.webp"
import melbourneicon from "../img/melbourne.webp"
// import canberraicon from "../img/canberra.webp"
// import Brisbaneicon from "../img/Brisbane.webp"
import emailicon from "../img/email.webp"
import phonecallicon from "../img/phone.webp"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";


function Getinrouch() {
    
    const form = useRef(null);

    const sendEmail = (e) => {
        e.preventDefault();
        console.log(form.current);
        // emailjs.sendForm('service_ko10zvt', 'template_n33ntfv', form.current, 'RKTFt9EYME-RVds_P')
        emailjs.sendForm('service_grj3mw8', 'template_j1k2g6f', form.current, 'gbx2901Kn3xIwxouB')
        .then((result) => {
            console.log(result.text,"text");
            document.getElementById('exampleForm.ControlInput1').value = '';
            document.getElementById('exampleForm.ControlInput2').value = '';
            document.getElementById('exampleForm.ControlInput3').value = '';
            document.getElementById('exampleForm.ControlInput4').value = '';
            document.getElementById('exampleForm.ControlTextarea1').value = '';
            document.getElementById('massgelines').innerHTML = '<span class="thankyo">THANK YOU FOR YOUR MESSAGE. IT HAS BEEN SENT.</span>';
        }, (error) => {
            console.log(error.text,"error text");
            document.getElementById('massgelines').innerHTML = '<span class="errorline">One or more fields have an error. Please check and try again.</span>';
        });
    };
    function onChange(value) {
        console.log("Captcha value:", value);
    }
    // const [toSend, setToSend] = useState({
    //     user_name: '',
    //     mobile_number: '',
    //     user_email: '',
    //     email_subject: '',
    //     message: '',
    // });

    // const onSubmit = (e) => {
    //     e.preventDefault();

    //     emailjs.sendForm("service_ko10zvt", "template_9lspcnx", form.current, "USER_ID").then(
    //         (result) => {
    //             alert("Message Sent Successfully");
    //             console.log(result.text);
    //         },
    //         (error) => {
    //             console.log(error.text);
    //         }
    //     );

    //     send(
    //         'service_ko10zvt',
    //         'template_9lspcnx',
    //         toSend,
    //         'User ID'
    //     ).then((response) => {
    //             console.log('SUCCESS!', response.status, response.text);
    //     }).catch((err) => {
    //             console.log('FAILED...', err);
    //     });
    // };

    // const handleChange = (e) => {
    //     setToSend({ ...toSend, [e.target.name]: e.target.value });
    // };

    function Mailto({ email, subject, body, ...props }) {
        return (
          <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
            {props.children}
          </a>
        );
      }
    


    return (
        <div className="get_in_touch" id="contect9864">
            <div className="bannerhome"  style={{ backgroundImage: `url(${contectpageimg})` }}>
                <div className="container">
                    <div className="bannersection">
                        <div className="bannersec_conetct">
                            <h1>Contact Us</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="contectboxs">
                    <div className="row">
                        <div className="col-4 phonebox">
                            <a href="tel:+61 3 97204496">
                                <div>
                                    <img src={phonecallicon} className="phoneicons" alt="sitelogo"  width="100px" />
                                </div>
                                <div className="m-2">
                                    <h2>PHONE</h2>
                                </div>
                                <div>    
                                    <h5>+61 3 97204496</h5>
                                </div>
                            </a>
                        </div>
                        
                        
                        <div className="col-4 emailbox">
                            <Mailto email="info@edimarkt.com" subject="Edimarkt" body="Edimarkt">
                                <div>
                                    <img src={emailicon} className="phoneicons" alt="sitelogo"  width="100px" />
                                </div>
                                <div className="m-2">    
                                    <h2>EMAIL</h2>
                                </div>
                                <div>    
                                    <h5> info@edimarkt.com</h5>
                                </div>
                            </Mailto>    
                        </div>
                        
                    </div>
                </div>
                <div className="contect_info">
                    <div className="row">
                        <div className="col contect_info_col">
                        <h1 className="contect_us_hadding">Our Offices</h1>
                            {/* <div className="icon_w_text d-flex align-items-center">
                                <div className="icon_s">
                                <img src={Sydneyicon} className="" alt="sitelogo"  width="100px" />
                                </div>
                                <div className="text_s">
                                    <h3>Sydney</h3>
                                    <h5>Lvl 2, 10 Bond St Sydney NSW 2000</h5>
                                </div>
                            </div> */}
                            <div className="icon_w_text d-flex align-items-center">
                                <div className="icon_s">
                                <img src={melbourneicon} className="" alt="sitelogo"  width="100px" />
                                </div>
                                <div className="text_s">
                                    <h3>Melbourne</h3>
                                    <h5>27 Hadlow Drive , Wantirna ,  victoria, 3152 </h5>
                                </div>
                            </div>
                            {/* <div className="icon_w_text d-flex align-items-center">
                                <div className="icon_s">
                                <img src={canberraicon} className="" alt="sitelogo"  width="100px" />
                                </div>
                                <div className="text_s">
                                    <h3>Canberra</h3>
                                    <h5>Lvl 4, 40 Cameron Av Belconnen ACT 2617</h5>
                                </div>
                            </div> */}
                            {/* <div className="icon_w_text d-flex align-items-center">
                                <div className="icon_s">
                                <img src={Brisbaneicon} className="" alt="sitelogo"  width="100px" />
                                </div>
                                <div className="text_s">
                                    <h3>Brisbane</h3>
                                    <h5>Lvl 5, 46 Edward Street Brisbane QLD 4000</h5>
                                </div>
                            </div> */}
                            {/* <div className="email_number">
                                <div className="row">
                                    <div className="col">
                                        <div className="icon_text">
                                            <div>
                                                <img src={phonecallicon} className="" alt="sitelogo"  width="30px" />
                                            </div>  
                                            <div> 
                                                <h5> +61 2 9657 0999</h5>
                                            </div>    
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="icon_text">
                                            <div>
                                                <img src={emailicon} className="" alt="sitelogo"  width="30px" />
                                            </div>
                                            <div>    
                                                <h5> info@Edimarkt.com</h5>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="col contect_form_col">
                            <div className="contectform">
                                <form ref={form} onSubmit={sendEmail}>
                                    <Form.Group size="lg" className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Your name</Form.Label>
                                        <Form.Control type="text" name="to_name" placeholder="Enter your name" required/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                        <Form.Label>Your mobile number</Form.Label>
                                        <Form.Control type="number" name="mobile_number" placeholder="Enter your Number" required/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" name="user_email" placeholder="name@example.com" required/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                        <Form.Label>Subject</Form.Label>
                                        <Form.Control type="text" name="email_subject" placeholder="Subject" required/>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Massage</Form.Label>
                                        <Form.Control as="textarea" name="message" rows={3} />
                                    </Form.Group>

                                    <ReCAPTCHA sitekey={"6LcgyRYpAAAAAG0XloTXM8x6Y5CVni7Dje81jfUT"} onChange={onChange} required/>
                                    <Button variant="light" type="submit" className="contectform_sumbitbtn" >Submit</Button>
                                    <div id="massgelines">
                                    
                                    </div>
                                </form>
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default Getinrouch;