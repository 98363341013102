/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Routes } from "react-router-dom";
import Homemain from "./component/home-page";
import Aboutuspage from "./component/aboutus";
import Investors from "./component/investors";
// import Partners from "./component/partners";
import Getintouch from "./component/get-in-touch";
import Advisorypage from "./component/advisory-consulting"
import Dataintegrationpage from "./component/data-integration"
import Managedpage from "./component/managed"
import Healthpage from "./component/health"
import Retailpage from "./component/retail"
// import Clientstorypage from "./component/client-story"
import Wlthbaipage from "./component/wlthbai"
import { useState } from "react";



function Navbar() {
    let path = window.location.pathname;

    console.log(path);
    useState(() => {
        window.scrollTo = 0;
        window.scrollY = 0;
    }, [path]);
    return (
        <Routes>

            <Route exact path="/" element={<Homemain />} />
            <Route exact path="/aboutus" element={<Aboutuspage />} />
            <Route exact path="/investors" element={<Investors />} />
            {/* <Route exact path="/Partners" element={<Partners />} /> */}
            <Route exact path="/get-in-touch" element={<Getintouch />} />
            <Route exact path="/advisory-consulting" element={<Advisorypage />} />
            <Route exact path="/data-integration" element={<Dataintegrationpage />} />
            <Route exact path="/managed" element={<Managedpage />} />
            <Route exact path="/health" element={<Healthpage />} />
            <Route exact path="/retail" element={<Retailpage />} />
            {/* <Route exact path="/client-story" element={<Clientstorypage />} /> */}
            <Route exact path="/wlthbai" element={<Wlthbaipage />} />
            {/* <Route path="*" element={<div>404 Not Found</div>} /> */}
        </Routes>
    );
}

export default Navbar;


