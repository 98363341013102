import React from 'react';
import '../css/App.css';
import sitelogo from '../img/sitmenlogo.webp';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
function Mailto({ email, subject, body, ...props }) {
  return (
    <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
      {props.children}
    </a>
  );
}



function Sitefooter() {
  return (
    <div className="site-footer">
      <div className="container">
        <div className="row footerconter">
          <div className="col-2">
            <Link to="/">
              <img src={sitelogo} className="sitlogo" alt="sitelogo" width="200" />
            </Link>
            <div className='socialicon'>
            </div>
          </div>
          <div className='col-2'>
            <h4>About Us</h4>
            <ul>
              <li><Link to="/">Our Company</Link></li>
              {/* <li><Link to="/">Investors</Link></li> */}
              <li><Link to="/">Get In Touch</Link></li>
            </ul>
          </div>
          <div className="col-2">
            <h4>Services</h4>
            <ul>
              <li><Link to="/">Advisory & Consulting</Link></li>
              <li><Link to="/">Business Applications</Link></li>
              <li><Link to="/">Cloud Services</Link></li>
              <li><Link to="/">Integration</Link></li>
              <li><Link to="/">Managed Services</Link></li>
            </ul>
          </div>
          <div className="col-2">
            <h4>Industries</h4>
            <ul>
              <li><Link to="/">Defence</Link></li>
              <li><Link to="/">Federal and State Government</Link></li>
              <li><Link to="/">Financial Services</Link></li>
              <li><Link to="/">Higher Education</Link></li>
              <li><Link to="/">Local Government</Link></li>
              <li><Link to="/">Manufacturing</Link></li>
              <li><Link to="/">Utilities</Link></li>
            </ul>
          </div>
          <div className="col-4">
            <h4>Stay in the loop</h4>
            <div className="newsletter">
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Email"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <Button variant="outline-secondary" id="button-addon2">
                  Button
                </Button>
              </InputGroup>
            </div>
            <ul>
              <li>
                <Link to="/">27 Hadlow drive , Wantirna,<br></br> victoria , 3152.</Link>
              </li>
              <li>
                <a href="tel:+61 3 97204496">+61 3 97204496</a><br />
                {/* <Link to="/">+61 2 9657 0999</Link> */}
                <Mailto email="info@edimarkt.com" subject="Edimarkt" body="Edimarkt">
                  info@edimarkt.com
                </Mailto>,
              </li>
            </ul>
          </div>
        </div>
        <div className="row mobhilfooter">
          <div className="col">
            <Link to="/">
              <img src={sitelogo} className="sitlogo" alt="sitelogo" width="200" />
            </Link>
            <div className='socialicon'>
            </div>
          </div>
          <Navbar expand="lg">
            <Nav className="me-auto">
              <NavDropdown title="Service" id="basic-nav-dropdown">
                <NavDropdown.Item ><Link to="/data-integration" >Data & Integration</Link></NavDropdown.Item>
                <NavDropdown.Item ><Link to="/managed" >Managed Services</Link></NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Industries" id="basic-nav-dropdown">
                <NavDropdown.Item ><Link to="/health">Health</Link></NavDropdown.Item>
                <NavDropdown.Item ><Link to="/retail">Retail</Link></NavDropdown.Item>
              </NavDropdown>
              <Nav.Link><Link to="/Partners" >Partners</Link></Nav.Link>
              <Nav.Link ><Link to="/client-story" >Client Story</Link></Nav.Link>
              <NavDropdown title="About Us" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1"><Link to="/aboutus" >Our Company</Link></NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3"><Link to="/get-in-touch" >Get In Touch</Link></NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar>
          <div className="col">
            <h4>Stay in the loop</h4>
            <div className="newsletter">
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Email"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                />
                <Button variant="outline-secondary" id="button-addon2">
                  Button
                </Button>
              </InputGroup>
            </div>
            <ul>
              <li>
                <Link to="/">27 Hadlow drive , Wantirna,<br></br> victoria , 3152.</Link>
              </li>
              <li>
                <a href="tel:+61 3 97204496">+61 3 97204496</a><br />
                {/* <Link to="tel:++61 3 97204496">+61 3 97204496</Link><br/> */}
                <Mailto email="info@edimarkt.com" subject="Edimarkt" body="Edimarkt">
                  info@edimarkt.com
                </Mailto>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright_line">
        <ul>
          <li>© 2022 Edimarkt</li>
          <li><Link to="/">Privacy Policy</Link></li>
          {/* <li><Link to="/">Terms of UseAnti-Slavery Policy</Link></li> */}
        </ul>
      </div>
      <div className="contect_us_fix">
        <Link to="/get-in-touch" className="whats-button" target="_blank">Contact Us</Link>
      </div>
    </div>
  );
}

export default Sitefooter;
