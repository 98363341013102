import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
// import Siteheader from './component/header';
// import Sitefooter from './component/footer';
import sitebannerimg from "../img/aboutus.webp";
import taxtwimg from "../img/abouttextwimg.webp"
import sitelogo from "../img/sitmenlogo.webp";
// import Microsoftlogo from "../img/Microsoft.webp";
import boomilogo from "../img/boomi.webp";
// import ampologo from "../img/ampol.webp";
// import micropatner from "../img/micropatner.webp";
import Celigo from "../img/Celigo.webp";
// import software from "../img/siftware.webp";
// import boomibanner from "../img/boomibanner.webp";
// import softag from "../img/softag.webp";
// import banklogo from "../img/banklogo.webp";
// import smartsheetlogo from "../img/smartsheet.webp";
// import boomipatnerlogo from "../img/boomipatner.webp";
// import peoplelogo from "../img/people.webp";
import balancelogo from "../img/balance.webp";
import handshakelogo from "../img/handshake.webp";
import projectmanagement from "../img/project-management.webp";
import teamfourlogo from "../img/teamfour.webp";
import selfconfidencelogo from "../img/selfconfidence.webp";
import manufacturinglogo from "../img/manufacturings.webp";
// import temaoneimg from "../img/temaone.webp";
import temaoneimg from "../img-2/teamone.jpg";
import tematwoimg from "../img/tematwo.webp";
// import temathreeimg from "../img/temathree.webp";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};


function aboutus() {
    return (
      <div className="aboutuspage" id="about987">
        <div className="aboutus">
            <div className="bannerhome"  style={{ backgroundImage: `url(${sitebannerimg})` }}>
                <div className="container">
                    <div className="bannersection">
                        <div className="bannersec_conetct">
                            <h1>About US</h1>
                            <h3>Move to Future with Clarity<br/>Trust in US</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="img-with-text">
                <div className="row">
                <div className="col imgtextcont">
                    <img src={taxtwimg} className="imgwithtext" alt="sitelogo"  width="100%" />
                </div>
                <div className="col abouttext">
                    <div className="site-text-content">
                    <h5>How do you journey to wherever’s next?</h5>
                    <h5>How do you know the steps you’re taking are heading in the right direction?</h5>    
                    <h1>Welcome to the world of Edimarkt</h1>
                    <h4>Advisory specialists at scale. </h4>
                    <span><h6>With technological depth and breadth, tactical cross-skills and IP tools, we clear the
                         line of sight between advice, implementation, and adoption.</h6></span>
                        <span><h6>Technologically competent and empathetically human. Ensuring you work
                             with the right people, to solve the right challenges.</h6></span>
                        <span><h6>Proven processes, honed from thousands of technology deployments across dozens of 
                            industries and hundreds of organisations nationwide, result in effective and elegant end-to-end outcomes.</h6></span>
                        <span><h6>For wherever you need to go next.
                             For wherever the future is going.</h6></span>
                             <h4>Edimarkt, we’ll lead you there.</h4>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="timelineimgwimg dekstop-timeline">
            <div className="history-tl-container">
                <ul className="tl">
                    <li className="tl-item twofif" ng-repeat="item in retailer_history">
                        <div className='row d-flex align-items-end'>
                            <div className="col">
                                <div className="item-detail"><img src={sitelogo} className="sitlogo" alt="sitelogo"  width="200" /></div> 
                                <div className="item-title">Startup in 2013</div>
                            </div>
                            <div className="col">
                                <div className="item-detail"><img src={boomilogo} className="sitlogo" alt="sitelogo"  width="160" /></div> 
                                <div className="item-title">Partnership with boomi</div>
                            </div>
                            {/* <div className="col">
                                <div className="item-detail"><img src={""} className="sitlogo" alt=""  width="200" /></div> 
                                <div className="item-title">our Microsoft services arm secures key clients</div>
                            </div> */}

                        </div>    
                    </li>

                       <li className="tl-item twosex" ng-repeat="item in retailer_history">
                        <div className='row d-flex'>
                            <div className="col">
                                <div className="item-detail"><img src={Celigo} className="sitlogo" alt="sitelogo"  width="120" /></div> 
                                <div className="item-title">Partnership with Celigo</div>
                            </div>
                            <div className="col">
                                {/* <div className="item-detail"><img src={micropatner} className="sitlogo" alt="sitelogo"  width="200" /></div> */}
                            </div>  
                            {/* <div className="col">
                                
                            </div> */}
                        </div>    
                    </li>
                   {/* <li className="tl-item twoseven" ng-repeat="item in retailer_history">
                        <div className='row d-flex'>
                            <div className="col">
                                <div className="item-detail"><img src={software} className="sitlogo" alt="sitelogo"  width="250" /></div> 
                                <div className="item-title">EDI Market founded</div>
                            </div>
                            <div className="col">
                                
                            </div>
                            <div className="col-4">
                                
                            </div>
                        </div>    
                    </li>
                    <li className="tl-item twoeight" ng-repeat="item in retailer_history">
                        <div className='row d-flex'>
                            <div className="col d-flex align-items-center">
                                <div className="item-detail"><img src={boomibanner} className="sitlogo" alt="sitelogo"  width="80" /></div> 
                                <div className="item-title">boomi APJ Partner of the year</div>
                            </div>
                            <div className="col d-flex align-items-center">
                                <div className="item-detail"><img src={boomilogo} className="sitlogo" alt="sitelogo"  width="125" /></div> 
                                <div className="item-title">30+ Boomi clients</div>
                            </div>
                            <div className="col d-flex align-items-center">
                                <div className="item-detail"><img src={sitelogo} className="sitlogo" alt="sitelogo"  width="150" /></div> 
                                <div className="item-title">EDI Market founded</div>
                            </div>
                        </div>    
                    </li>
                    <li className="tl-item twonine" ng-repeat="item in retailer_history">
                        <div className='row d-flex'>
                            <div className="col">
                                <div className="item-detail"><img src={softag} className="sitlogo" alt="sitelogo"  width="150" /></div> 
                                <div className="item-title">APJ Partner of the year award</div>
                            </div>
                            <div className="col d-flex align-items-center">
                                <div className="item-detail"><img src={banklogo} className="sitlogo" alt="sitelogo"  width="70" /></div> 
                                <div className="item-title">60+ Australian LGAs Local Government Area</div>
                            </div>
                            <div className="col">
                                
                            </div>
                        </div>    
                    </li>
                    <li className="tl-item twoten" ng-repeat="item in retailer_history">
                        <div className='row d-flex'>
                            <div className="col">
                                <div className="item-detail"><img src={smartsheetlogo} className="sitlogo" alt="sitelogo"  width="200" /></div> 
                                <div className="item-title">EDI Market founded</div>
                            </div>
                            <div className="col d-flex align-items-center">
                                <div className="item-detail"><img src={boomipatnerlogo} className="sitlogo" alt="sitelogo"  width="80" /></div> 
                                <div className="item-title">Boomi APJ Partner of the Year award</div>
                            </div>
                            <div className="col d-flex align-items-center">
                                <div className="item-detail"><img src={peoplelogo} className="sitlogo" alt="sitelogo"  width="80" /></div> 
                                <div className="item-title">400 emloyees 100+ data integration consultants</div>
                            </div>
                        </div>    
                    </li>
                    <li className="tl-item twoelev" ng-repeat="item in retailer_history">
                        <div className='row d-flex'>
                            <div className="col">
                                <div className="item-detail"><img src={boomilogo} className="sitlogo" alt="sitelogo"  width="160" /></div> 
                                <div className="item-title">EDI Market becomes only select partner in australia</div>
                            </div>
                            <div className="col d-flex align-items-center">
                            <div className="item-detail"><img src={peoplelogo} className="sitlogo" alt="sitelogo"  width="80" /></div> 
                                <div className="item-title">500 employees 600+ Clients</div>
                            </div>
                            <div className="col">
                                
                            </div>
                        </div>    
                    </li> */}
                </ul>
            </div> 
        </div>
        <div className="timelineimgwimg mobhil_timeline">
            <div className="history-tl-container">
                <ul className="tl">
                    <li className="tl-item twofif" ng-repeat="item in retailer_history">
                        <h1 className="round_section">2013</h1>
                        <div className="row">
                            <div className="col">
                                <div className="item-detail"><img src={sitelogo} className="sitlogo" alt="sitelogo"  width="200" /></div> 
                                <div className="item-title">Stratup in 2013</div>
                            </div>
                            {/* <div className="col">
                                <div className="item-detail"><img src={Microsoftlogo} className="sitlogo" alt="sitelogo"  width="200" /></div> 
                                <div className="item-title">our Microsoft services arm secures key clients</div>
                            </div> */}
                            <div className="col">
                                <div className="item-detail"><img src={boomilogo} className="sitlogo" alt="sitelogo"  width="160" /></div> 
                                <div className="item-title">Partnership with boomi</div>
                            </div>
                        </div>    
                    </li>

                     <li className="tl-item twosex" ng-repeat="item in retailer_history">
                        <h1 className="round_section">2016</h1>
                        <div className="row">
                            <div className="col">
                                <div className="item-detail"><img src={Celigo} className="sitlogo" alt="sitelogo"  width="120" /></div> 
                                <div className="item-title">Partnership with Celigo</div>
                            </div>
                            {/* <div className="col">
                                <div className="item-detail"><img src={micropatner} className="sitlogo" alt="sitelogo"  width="200" /></div>
                            </div> */}
                        </div>    
                    </li>

                    {/* <li className="tl-item twoseven" ng-repeat="item in retailer_history">
                    <h1 className="round_section">2017</h1>
                    <div className="row">
                        <div className="col">
                            <div className="item-detail"><img src={software} className="sitlogo" alt="sitelogo"  width="250" /></div> 
                            <div className="item-title">EDI Market founded</div>
                        </div>
                    </div>    
                    </li>

                    <li className="tl-item twoeight" ng-repeat="item in retailer_history">
                    <h1 className="round_section">2018</h1>
                    <div className="row">
                        <div className="col">
                            <div className="item-detail"><img src={boomibanner} className="sitlogo" alt="sitelogo"  width="80" /></div> 
                            <div className="item-title">boomi APJ Partner of the year</div>
                        </div>
                        <div className="col">
                            <div className="item-detail"><img src={boomilogo} className="sitlogo" alt="sitelogo"  width="125" /></div> 
                            <div className="item-title">30+ Boomi clients</div>
                        </div>
                        <div className="col">
                            <div className="item-detail"><img src={sitelogo} className="sitlogo" alt="sitelogo"  width="150" /></div> 
                            <div className="item-title">EDI Market founded</div>
                        </div>
                    </div>    
                    </li>

                    <li className="tl-item twonine" ng-repeat="item in retailer_history">
                    <h1 className="round_section">2019</h1>
                    <div className="row">
                        <div className="col">
                            <div className="item-detail"><img src={softag} className="sitlogo" alt="sitelogo"  width="150" /></div> 
                            <div className="item-title">APJ Partner of the year award</div>
                        </div>
                        <div className="col">
                            <div className="item-detail"><img src={banklogo} className="sitlogo" alt="sitelogo"  width="70" /></div> 
                            <div className="item-title">60+ Australian LGAs Local Government Area</div>
                        </div>
                    </div>    
                    </li>

                    <li className="tl-item twoten" ng-repeat="item in retailer_history">
                    <h1 className="round_section">2020</h1>
                    <div className="row">
                        <div className="col">
                            <div className="item-detail"><img src={smartsheetlogo} className="sitlogo" alt="sitelogo"  width="200" /></div> 
                            <div className="item-title">EDI Market founded</div>
                        </div>
                        <div className="col">
                            <div className="item-detail"><img src={boomipatnerlogo} className="sitlogo" alt="sitelogo"  width="80" /></div> 
                            <div className="item-title">Boomi APJ Partner of the Year award</div>
                        </div>
                        <div className="col">
                            <div className="item-detail"><img src={peoplelogo} className="sitlogo" alt="sitelogo"  width="80" /></div> 
                            <div className="item-title">400 emloyees 100+ data integration consultants</div>
                        </div>
                    </div>    
                    </li>

                    <li className="tl-item twoelev" ng-repeat="item in retailer_history">
                    <h1 className="round_section">2021</h1>
                    <div className="row">
                        <div className="col">
                            <div className="item-detail"><img src={boomilogo} className="sitlogo" alt="sitelogo"  width="160" /></div> 
                            <div className="item-title">EDI Market becomes only select partner in australia</div>
                        </div>
                        <div className="col">
                        <div className="item-detail"><img src={peoplelogo} className="sitlogo" alt="sitelogo"  width="80" /></div> 
                            <div className="item-title">500 employees 600+ Clients</div>
                        </div>
                    </div>    
                    </li> */}
                </ul>
            </div> 
        </div>
        <div className="container">
            <div className="advantage-section">
                <h1>The Edimarkt Advantage</h1>
                <h3>We bring our specialised experience and strategic partnerships to bear – offering assured leadership at critical<br/>moments of organisational evolution.</h3>
                <div className="row">
                    <div className="col">
                        <div className="advantagelist">
                            <div className="advantagelogo">
                                <img src={balancelogo} alt="sitelogo"  width="70" />
                            </div>    
                            <div className="advantagecontent">
                                <h2>Unprecedented <br/>Scale</h2>
                                <h6>Modernised, simplified and optimised for more than 600 clients</h6>
                           </div> 
                        </div>
                        <div className="advantagelist">
                            <div className="advantagelogo">
                                <img src={projectmanagement} alt="sitelogo"  width="70" />
                            </div>    
                            <div className="advantagecontent">
                                <h2>Unique <br/>Approach</h2>
                                <h6>Quick-to-market, specialised strategy and capability with strong IP and cross skills</h6>
                           </div> 
                        </div>
                        <div className="advantagelist">
                            <div className="advantagelogo">
                                <img src={teamfourlogo} alt="sitelogo"  width="70" />
                            </div>    
                            <div className="advantagecontent">
                                <h2>Over 200 Security<br/>-Cleared Staff</h2>
                                <h6>One of the largest consulting teams working with Defence and Federal Government</h6>
                           </div> 
                        </div>
                    </div>
                    <div className="col">
                        <div className="advantagelist">
                            <div className="advantagelogo">
                                <img src={manufacturinglogo} alt="sitelogo"  width="70" />
                            </div>    
                            <div className="advantagecontent">
                                <h2>Strong <br/>Industry Focus</h2>
                                <h6>Industry-specific expertise across Utilities, Government, Education and more</h6>
                           </div> 
                        </div>
                        <div className="advantagelist">
                            <div className="advantagelogo">
                                <img src={selfconfidencelogo} alt="sitelogo"  width="70" />
                            </div>    
                            <div className="advantagecontent">
                                <h2>Proudly <br/>Australian</h2>
                                <h6>100% Australian owned with offices across the nation</h6>
                           </div> 
                        </div>
                        <div className="advantagelist">
                            <div className="advantagelogo">
                                <img src={handshakelogo} alt="sitelogo"  width="70" />
                            </div>    
                            <div className="advantagecontent">
                                <h2>Strong <br/>Partner Relationships</h2>
                                <h6>Strategically partnered with several category-leaders and visionaries</h6>
                           </div> 
                        </div>
                    </div>
                </div>
            </div>
            <div className="team_section">
                <h1>Our Team</h1>
                <Carousel responsive={responsive}>
                <div className="teambox">
                    <img src={temaoneimg} className="teamimgdp" alt="sitelogo"  width="400" />
                    <div className="teaminfo">
                        <h2>Anshuman Bezbaruah</h2>
                        <h5>Founder</h5>
                    </div>
                </div>
                {/* <div className="teambox">
                    <img src={temathreeimg} className="teamimgdp" alt="sitelogo"  width="400" />
                    <div className="teaminfo">
                        <h2>Alex Ciobanu</h2>
                        <h5>Co-Founder</h5>
                    </div>
                </div> */}
                <div className="teambox">
                    <img src={tematwoimg} className="teamimgdp" alt="sitelogo"  width="400" />
                    <div className="teaminfo">
                        <h2>Chinmayee</h2>
                        <h5>Head of accounts</h5>
                    </div>
                </div>  
                {/*<div className="teambox">
                    <img src={temaoneimg} className="teamimgdp" alt="sitelogo"  width="400" />
                    <div className="teaminfo">
                        <h2>Stephen Kowal</h2>
                        <h5>Chief Executive Officer</h5>
                    </div>
                </div>
                <div className="teambox">
                    <img src={tematwoimg} className="teamimgdp" alt="sitelogo"  width="400" />
                    <div className="teaminfo">
                        <h2>Herjit Saini</h2>
                        <h5>Head of Legal</h5>
                    </div>
                </div>
                <div className="teambox">
                    <img src={temathreeimg} className="teamimgdp" alt="sitelogo"  width="400" />
                    <div className="teaminfo">
                        <h2>Richard Shaw</h2>
                        <h5>Chief Financial Officer</h5>
                    </div>
                </div>
                <div className="teambox">
                    <img src={temaoneimg} className="teamimgdp" alt="sitelogo"  width="400" />
                    <div className="teaminfo">
                        <h2>Stephen Kowal</h2>
                        <h5>Chief Executive Officer</h5>
                    </div>
                </div>
                <div className="teambox">
                    <img src={tematwoimg} className="teamimgdp" alt="sitelogo"  width="400" />
                    <div className="teaminfo">
                        <h2>Herjit Saini</h2>
                        <h5>Head of Legal</h5>
                    </div>
                </div>
                <div className="teambox">
                    <img src={temathreeimg} className="teamimgdp" alt="sitelogo"  width="400" />
                    <div className="teaminfo">
                        <h2>Richard Shaw</h2>
                        <h5>Chief Financial Officer</h5>
                    </div>
                </div>
                <div className="teambox">
                    <img src={temaoneimg} className="teamimgdp" alt="sitelogo"  width="400" />
                    <div className="teaminfo">
                        <h2>Stephen Kowal</h2>
                        <h5>Chief Executive Officer</h5>
                    </div>
                </div>
                <div className="teambox">
                    <img src={tematwoimg} className="teamimgdp" alt="sitelogo"  width="400" />
                    <div className="teaminfo">
                        <h2>Herjit Saini</h2>
                        <h5>Head of Legal</h5>
                    </div>
                </div>
                <div className="teambox">
                    <img src={temathreeimg} className="teamimgdp" alt="sitelogo"  width="400" />
                    <div className="teaminfo">
                        <h2>Richard Shaw</h2>
                        <h5>Chief Financial Officer</h5>
                    </div>
                </div> */}
              </Carousel>
            </div>
        </div>    
      </div>
    );
  }
  
export default aboutus;
  