import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const doughnutchart = {
  labels: ['Other', 'Local and state government', 'Federal government', 'Education', 'Defence', 'Superannuateon and financial planning', 'utilities'],
  datasets: [
    {
      label: '# of Votes',
      data: [25, 13, 25, 4, 20, 5, 8,],
      backgroundColor: [
        'rgba(245, 192, 116, 1)',
        'rgba(3, 114, 120, 1)',
        'rgba(0, 137, 143, 1)',
        'rgba(81, 168, 170, 1)',
        'rgba(150, 193, 199, 1)',
        'rgba(235, 148, 2, 1)',
        'rgba(247, 166, 75, 1)',
      ],
      borderColor: [
        'rgba(245, 192, 116, 1)',
        'rgba(3, 114, 120, 1)',
        'rgba(0, 137, 143, 1)',
        'rgba(81, 168, 170, 1)',
        'rgba(150, 193, 199, 1)',
        'rgba(235, 148, 2, 1)',
        'rgba(247, 166, 75, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export function Progresschart() {
  return <Doughnut data={doughnutchart} />;
}

export default Progresschart;