
import React from "react";
import '../css/App.css';
// import Card from 'react-bootstrap/Card';
// import 'bootstrap/dist/js/bootstrap.bundle.js'
import Button from 'react-bootstrap/Button';
import sitebannerimg from "../img/Rectangle2.webp";
import taxtwimg from "../img/img-w-text-home.webp"
import teamicon from "../img/team1.webp"
import profiticon from "../img/profit1.webp"
import cludicon from "../img/cloud-service1.webp"
import dataicon from "../img/data1.webp"
import customericon from "../img/customerservice1.webp"
// import Defence from "../img/Defence.webp"
// import Manufacturing from "../img/Manufacturing.webp"
// import Federal from "../img/Federal.webp"
// import Utilities from "../img/Utilities.webp"
// import Local from "../img/Local.webp"
// import Higher from "../img/Higher.webp"
// import Financial from "../img/Financial.webp"
import Finance from "../img/in_fianace.png";
import Healthcare from "../img/in_healthcare.png";
import Education from "../img/in_edu.png";
import Organizations from "../img/in_organization.png";
import Government from "../img/in_goverment.png";
import Manufacturing from "../img/in_manufacturing.png";
import Ecommerce from "../img/in_ecomerce.png";
import EnergyUtilities from "../img/in_energey.png";
import Transportation from "../img/in_transport.png";
import Technology from "../img/in_itserve.png";
import RealEstate from "../img/in_realstate.png";
import Hospitality from "../img/in_hospitality.png";
import Agriculture from "../img/in_agriculture.png";
import Microsoftlogo from "../img/Microsoft.webp"
import boomilogo from "../img/boomi.webp"
import siftwarelogo from "../img/siftware.webp"
import opentextlogo from "../img/opentext.webp"
import qadlogo from "../img/qad.webp"
import smartsheetlogo from "../img/smartsheet.webp"
// import fastblog from "../img/fastbolg.webp"
// import secblog from "../img/secblog.webp"
// import thrdblog from "../img/thrdblog.webp"
import ctabanner from "../img/ctabanner.webp"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function Homemain() {
  const navigate = useNavigate();

  const redirectpath = (page) => {
    if (page === "learn-more") {
      navigate("/aboutus");
    } else if (page === "get-a-quote") {
      navigate("/get-in-touch");
    } else if (page === "get-in-touch") {
      navigate("/get-in-touch");
    } else {
      navigate("/get-in-touch");
    }
  }
  return (

    <div className="homepage" id="homepage9502">
      {/* banner section */}
      <div className="bannerhome" style={{ backgroundImage: `url(${sitebannerimg})` }}>
        <div className="container">
          <div className="bannersection">
            <div className="bannersec_conetct">
              {/* <h1>Do More.</h1>
                      <h1>Go Beyond.</h1> */}
              <h2 style={{ fontSize: "55px" }}><b>Move to Future with Clarity</b><br />Trust in US</h2>
              <div className="bannet_points">
                <ul>
                  <li>Advancing Your Growth Through Future-Ready Capabilities</li>
                  <li>Steering You Towards Boundless Growth</li>
                  <li>Business Growth Expertise You Can Leverage</li>
                  <li>Top-tier Business Solutions for Your Boundless Growth</li>
                  <li>Scale Your Growth with the Right Experts</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* text with img section   */}
      <div className="container">
        <div className="img-with-text">
          <div className="row">
            <div className="col imgtextcont">
              <img src={taxtwimg} className="imgwithtext" alt="sitelogo" width="100%" />
            </div>
            <div className="col">
              <div className="site-text-content">
                {/* <h1>We exist to help clients see exciting possibilities through technology that works today and into the future.
                We bring specialised experience offering assured leadership as your organisation evolves.</h1>
                <Button variant="outline-primary sitebutton">GET IN TOUCH</Button>{' '} */}
                <h1>We provide businesses and organizations with expert guidance and technological advantages to spur and sustain new growth levels. Our reliable team of business consultants and technology specialists help you harness your growth possibilities and equip you to get there.</h1>
                <Button variant="outline-primary sitebutton" onClick={() => redirectpath("learn-more")}>Learn more</Button>{' '}
              </div>
            </div>
          </div>
        </div>
        {/* service section */}
        <div className="Services_section">
          {/* <h1>Services</h1>
            <h3>Scale reliably today and tomorrow with end-to-end capabilities</h3> */}
          <h1>Our Services</h1>
          <h3>End-to-end capabilities that help you scale your growth with clarity and precision</h3>
          <div className="serviceslist">
            <div className="row ">
              {/* 1 service box */}
              <div className="col-lg-4">
                {/* <img className="rounded-circle" src="data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Generic placeholder image" width="140" height="140"> */}
                <img src={teamicon} className="imgwithtext" alt="sitelogo" width="100px" />
                <div className="servivecontent">
                  <h2>Advisory & Consulting</h2>
                  <p>You can rely on our extensive experience and in-depth expertise to steer your business forward with confidence and unprecedented results. We tailor our advice to specifically address your issues and hit your goals. </p>
                  {/* <p>Experience you can trust as you transform your organisation</p> */}
                  {/* <p className="servicesbtn"><Button variant="outline-primary sitebuttonrepet">Go To Service</Button>{' '}</p> */}
                </div>
              </div>
              {/* 2 service box */}
              <div className="col-lg-4">
                {/* <img className="rounded-circle" src="data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Generic placeholder image" width="140" height="140"> */}
                <img src={profiticon} className="imgwithtext" alt="sitelogo" width="100px" />
                <div className="servivecontent">
                  <h2>Business Applications</h2>
                  <p>We provide modern and integrated software applications and tools to enhance business operations. From customer relationship management (CRM) to enterprise resource planning (ERP), you can utilize our leading-edge systems to stay ahead of your competition.</p>
                  {/* <p>Doing the complicated work so you leverage modern, integrated platforms</p> */}
                  {/* <p className="servicesbtn"><Button variant="outline-primary sitebuttonrepet">Go To Service</Button>{' '}</p> */}
                </div>
              </div>
              {/* 3 service box */}
              <div className="col-lg-4">
                {/* <img className="rounded-circle" src="data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Generic placeholder image" width="140" height="140"> */}
                <img src={cludicon} className="imgwithtext" alt="sitelogo" width="100px" />
                <div className="servivecontent">
                  <h2>Cloud Services</h2>
                  <p>Benefit from our uptime reliable, flexible, scalable, secure, user-friendly, well-backed up, and interoperable cloud services for a more optimized, cost-efficient, and agile experience.</p>
                  {/* <p>Improve cost-efficiencies and be more agile with the right cloud optimisation</p> */}
                  {/* <p className="servicesbtn"><Button variant="outline-primary sitebuttonrepet">Go To Service</Button>{' '}</p> */}
                </div>
              </div>
            </div>
            <div className="row secrow">
              <div className="col-lg-2">

              </div>
              {/* 4 service box */}
              <div className="col-lg-4">
                {/* <img className="rounded-circle" src="data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Generic placeholder image" width="140" height="140"> */}
                <img src={dataicon} className="imgwithtext" alt="sitelogo" width="100px" />
                <div className="servivecontent">
                  <h2>Data & Integration</h2>
                  <p>Experience seamless connections and interoperations with on-premise infrastructure and third-party applications that enable a smooth workflow, insight generation, and data-driven decision-making.</p>
                  {/* <p>Unlock insight and growth by easily connecting systems and applications</p> */}
                  {/* <p className="servicesbtn"><Button variant="outline-primary sitebuttonrepet">Go To Service</Button>{' '}</p> */}
                </div>
              </div>
              {/* 5 service box */}
              <div className="col-lg-4">
                {/* <img className="rounded-circle" src="data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==" alt="Generic placeholder image" width="140" height="140"> */}
                <img src={customericon} className="imgwithtext" alt="sitelogo" width="100px" />
                <div className="servivecontent">
                  <h2>Managed Services</h2>
                  <p>Leverage our expertise and offload your operational and IT functions to us so you can focus on your core activities and maximize your resources.</p>
                  {/* <p>Giving you peace of mind and a single point of contact</p> */}
                  {/* <p className="servicesbtn"><Button variant="outline-primary sitebuttonrepet">Go To Service</Button>{' '}</p> */}
                </div>
              </div>
              <div className="col-lg-2">

              </div>
            </div>
          </div>
          <Button variant="btn btn-outline-primary sitebutton d-flex mx-auto my-3" onClick={() => redirectpath('get-a-quote')}>Get a quote</Button>{' '}
        </div>
      </div>
      {/* partner section   */}
      <div className="logo_section">
        <h1>Our Partners</h1>
        {/* <h1>Partners</h1> */}
        <div className="logo-content">
          <div className="row">
            <div className="col-5 partaner_content">
              <div className="logo-contant-btn">
                <p>&nbsp;<br />&nbsp;<br />&nbsp;</p>
                {/* <Button variant="btn btn-outline-primary sitebuttonrepet">GET IN TOUCH</Button>{' '} */}
                &nbsp;<br />&nbsp;
              </div>
            </div>
            <div className="col-7 partner-logo">
              <div className="row">
                <div className="col">
                  <img src={Microsoftlogo} className="" alt="sitelogo" width="200px" />
                </div>
                <div className="col">
                  <img src={boomilogo} className="" alt="sitelogo" width="200px" />
                </div>
                <div className="col">
                  <img src={siftwarelogo} className="" alt="sitelogo" width="200px" />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <img src={opentextlogo} className="" alt="sitelogo" width="200px" />
                </div>
                <div className="col">
                  <img src={qadlogo} className="" alt="sitelogo" width="100px" />
                </div>
                <div className="col">
                  <img src={smartsheetlogo} className="" alt="sitelogo" width="200px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Industries section */}
      <div className="container">
        <div className="industries_section">
          <h1>Industries We Serve</h1>
          <h3>Access technological depth and breadth and industry expertise as you lead your organisation<br /> through change</h3>
          <div className="row industrieslist">
            <div className="col fastoneindustri">
              {/* 1 Industries */}
              <div className="sixshapliast defence">
                <a href="/"><div className="industrihover"><img src={Finance} className="" alt="sitelogo" width="200px" /></div></a>
              </div>
              {/* 2 Industries */}
              <div className="sixshapliast manufacturing">
                <a href="/"><div className="industrihover"><img src={Healthcare} className="" alt="sitelogo" width="200px" /></div></a>
              </div>
              {/* 3 Industries */}
              <div className="sixshapliast manufacturing">
                <a href="/"><div className="industrihover"><img src={Education} className="" alt="sitelogo" width="200px" /></div></a>
              </div>
              {/* 3 Industries */}
              <div className="sixshapliast manufacturing">
                <a href="/"><div className="industrihover"><img src={Agriculture} className="" alt="sitelogo" width="200px" /></div></a>
              </div>
            </div>
            <div className="col seconeindustri">
              {/* 4 Industries */}
              <div className="sixshapliast federal">
                <a href="/"><div className="industrihover"><img src={Government} className="" alt="sitelogo" width="200px" /></div></a>
              </div>
              {/* 5 Industries  */}
              <div className="sixshapliast unilities">
                <a href="/"><div className="industrihover"><img src={Manufacturing} className="" alt="sitelogo" width="200px" /></div></a>
              </div>
              {/* 6 Industries */}
              <div className="sixshapliast local">
                <a href="/"><div className="industrihover"><img src={Ecommerce} className="" alt="sitelogo" width="200px" /></div></a>
              </div>
              {/* 7 Industries */}
              <div className="sixshapliast local">
                <a href="/"><div className="industrihover"><img src={Organizations} className="" alt="sitelogo" width="200px" /></div></a>
              </div>
              {/* 7 Industries */}
              <div className="sixshapliast local">
                <a href="/"><div className="industrihover"><img src={Hospitality} className="" alt="sitelogo" width="200px" /></div></a>
              </div>
            </div>
            <div className="col thrdoneindustri">
              {/* 6 Industries */}
              <div className="sixshapliast higher">
                <a href="/"><div className="industrihover"><img src={Technology} className="" alt="sitelogo" width="200px" /></div></a>
              </div>
              {/* 7 Industries */}
              <div className="sixshapliast financial">
                <a href="/"><div className="industrihover"><img src={Transportation} className="" alt="sitelogo" width="200px" /></div></a>
              </div>
              {/* 7 Industries */}
              <div className="sixshapliast financial">
                <a href="/"><div className="industrihover"><img src={EnergyUtilities} className="" alt="sitelogo" width="200px" /></div></a>
              </div>
              {/* 7 Industries */}
              <div className="sixshapliast financial">
                <a href="/"><div className="industrihover"><img src={RealEstate} className="" alt="sitelogo" width="200px" /></div></a>
              </div>
            </div>
          </div>
        </div>
        {/* bolg section */}
        {/* <div className="blog-post">
            <h1>Blog</h1>
            <div className="row"> */}
        {/* 1 blog */}
        {/* <div className="col">
                <Card style={{ width: '22rem' }}>
                  <Card.Img variant="top" src={fastblog} />
                  <Card.Body>
                    <h6 className="blogdate">15, july,2022</h6>
                    <Card.Title>Lorem Ipsum is simply<br/> dummy text</Card.Title>
                    <Card.Text>
                    Lorem Ipsum is simply dummy text of<br/> the printing and typesetting industry.<br/> Lorem Ipsum has been the
                    </Card.Text>
                    <Button variant="outline-primary sitebuttonrepet">Go somewhere</Button>
                  </Card.Body>
                </Card>
              </div> */}
        {/* 2 blog */}
        {/* <div className="col">
              <Card style={{ width: '22rem' }}>
                  <Card.Img variant="top" src={secblog} />
                  <Card.Body>
                  <h6 className="blogdate">15, july,2022</h6>
                  <Card.Title>Lorem Ipsum is simply<br/> dummy text</Card.Title>
                    <Card.Text>
                    Lorem Ipsum is simply dummy text of<br/> the printing and typesetting industry.<br/> Lorem Ipsum has been the
                    </Card.Text>
                    <Button variant="outline-primary sitebuttonrepet">Go somewhere</Button>
                  </Card.Body>
                </Card>
              </div> */}
        {/* 3 blog */}
        {/* <div className="col">
              <Card style={{ width: '22rem' }}>
                  <Card.Img variant="top" src={thrdblog} />
                  <Card.Body>
                  <h6 className="blogdate">15, july,2022</h6>
                  <Card.Title>Lorem Ipsum is simply<br/> dummy text</Card.Title>
                    <Card.Text>
                    Lorem Ipsum is simply dummy text of<br/> the printing and typesetting industry.<br/> Lorem Ipsum has been the
                    </Card.Text>
                    <Button variant="outline-primary sitebuttonrepet">Go somewhere</Button>
                  </Card.Body>
              </Card>
              </div> */}
        {/* </div>
          </div> */}
      </div>
      {/* cta section   */}
      <div className="cta-section" style={{ backgroundImage: `url(${ctabanner})` }}>
        <div className="ctabackground">
          <h1>The Best of Business Leadership Guidance and Technological<br />Capabilities are Within Your Reach</h1>
          {/* <h1>Looking for a step-change in your <br /> business’ technology capabilities?</h1> */}
          {/* <h4>We’ll lead you there.</h4> */}
          <h3>Edimarkt is here to help your business make a mark</h3>
          <h4>We cater to businesses and organizations of all sizes and scopes. Our team will help you identify your pain points, optimize your performance, and maximize your growth potential.</h4>
          <Button variant="btn btn-outline-primary learn" onClick={() => redirectpath("get-in-touch")}>Get in touch</Button>{' '}
          {/* <Button variant="btn btn-outline-primary learn">LEARN MORE..</Button>{' '} */}
        </div>
      </div>
      {/* logo section   */}
      <div className="container">
        <div className="clientslogo">
          <h1>Clients</h1>
          {/* <h3>A track record serving over 600 clients across public and private sector to meet their business objectives,
              <br/> mission imperatives and transformation goals.</h3> */}
          <h3>We are trusted by over 600 clients whose partnership with us led to their enhanced operational capability that’s transformative, scalable, and future-ready!</h3>
          <Carousel responsive={responsive}>
            <div><img src={Microsoftlogo} className="" alt="sitelogo" width="200px" /></div>
            <div><img src={boomilogo} className="" alt="sitelogo" width="200px" /></div>
            <div><img src={opentextlogo} className="" alt="sitelogo" width="200px" /></div>
            <div><img src={siftwarelogo} className="" alt="sitelogo" width="200px" /></div>
            <div><img src={smartsheetlogo} className="" alt="sitelogo" width="200px" /></div>
            <div><img src={Microsoftlogo} className="" alt="sitelogo" width="200px" /></div>
            <div><img src={boomilogo} className="" alt="sitelogo" width="200px" /></div>
            <div><img src={opentextlogo} className="" alt="sitelogo" width="200px" /></div>
            <div><img src={siftwarelogo} className="" alt="sitelogo" width="200px" /></div>
            <div><img src={smartsheetlogo} className="" alt="sitelogo" width="200px" /></div>
          </Carousel>
          <Button variant="btn btn-outline-primary sitebutton d-flex mx-auto my-3" onClick={() => redirectpath("success-stories")}>Be one of these success stories</Button>{' '}
        </div>
      </div>
    </div>
  );
}

export default Homemain;