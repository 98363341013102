import React from "react";
import wlthbaibanner from "../img/wlthbaibanner.webp";
import Button from 'react-bootstrap/Button';
import abouttheclient from "../img/abouttheclient.webp";
import ClientStory from "../img/helthyleve.webp";
import introctabanner from "../img/introctabannersec.webp";
import Accordion from 'react-bootstrap/Accordion';
import "react-multi-carousel/lib/styles.css";


function Withbaipag() {
    return ( 
        <div className="wlthpage" id="wlthpage98">
            <div className="bannerhome"  style={{ backgroundImage: `url(${wlthbaibanner})` }}>
                <div className="container">
                    <div className="bannersection">
                        <div className="bannersec_conetct">
                            <h1>WLTH Boomi Data Integration</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="click_down_menu">
                <Button variant="link" href="#Introduction98" className="fixbuttons introduction">Introduction</Button>
                <Button variant="link" href="#service28" className="fixbuttons service_highlight">Service Highlight</Button>
                <Button variant="link" href="#benefits28" className="fixbuttons benefits">Partners</Button>
                <Button variant="link" href="#clintsto32" className="fixbuttons client_story">Client Story</Button>
            </div> 
            <div className="container">
                <div className="img-with-text" id="Introduction98">
                    <div className="row">
                        <div className="col imgtextcont">
                            <img src={abouttheclient} className="imgwithtext" alt="sitelogo"  width="600" />
                        </div>
                        <div className="col abouttext">
                            <div className="site-text-content">  
                                <h1>About the Client</h1>
                                <span><h6>WLTH (pronounced Wealth) is a Brisbane-based digital lending and payments fintech changing
                                     the money experience to a more positive one in Australia.</h6></span>
                                <span><h6>Their mission is to revolutionise financial services in Australia by helping banks, fintechs,
                                     and consumers by unlocking potential capabilities of the WLTH platform. Their residential and commercial
                                      lending solutions hit the market in 2020 and WLTH is now preparing for launching their state-of-the-art 
                                      payments application – WLTH Pay. While delivering a superior lending experience, WLTH is also making a 
                                      difference in the environment.</h6></span>
                                <span><h6>For every home loan that is settled, the company assists and empowers leading environmental organisation,
                                     “Parley for the Oceans” clean 50m2 of Australian beaches<br/>
                                     and coastline of plastic pollution. Parley is a leading environmental organisation that is working around the world,
                                      and in communities to protect the oceans from marine plastic pollution.</h6></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service_highlights" id="service28">
                    <h1 className="featured_hadding">Service Highlights</h1>
                    <h3 className='featurde_sulog'>Uncover insights in disconnected systems and turn them <br/>into moments that matter</h3>
                    <div className="row">
                        <div className="ascodiyan_tubing">
                            <div className="col-8 m-auto">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Business Opportunity</Accordion.Header>
                                        <Accordion.Body>
                                        <span className="acordyantext">WLTH aims to grow their lending platform, targeting A$1.2 billion in residential
                                        and commercial loans by 2022, which demands aggressive customer acquisition. The next phase of the company’s
                                        roadmap is to diversify with increased investments into its payment services, including launching of WLTH Pay.
                                        The success of their roadmap relies on a technology stack that offers customers a view of the entire WLTH
                                        financial portfolio and delivers a concierge of financial services. This demands an integrated technology 
                                        and data foundation.</span><br/>
                                        <br/>
                                        <span className="acordyantext">Dave Chapman, Chief Information Officer (CIO) at WLTH said, “We’re looking to
                                        take the headache out of finances, and whether it’s to manage personal money or a small-to-medium business,
                                        customers need to trust their payments experience will be reliable</span><br/>
                                        <br/>
                                        <span className="acordyantext">and easy. But access at anytime, anywhere doesn’t happen out of thin air – 
                                        there are hundreds of data sets running across different technologies and processes that need to be synchronised.”.</span><br/>
                                        <br/>
                                        <span className="acordyantext">With a skilled but small internal technology team, WLTH was looking for a strategic
                                         partner to advise and implement the best-in-breed integration that their roadmap requires.</span><br/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Edimarkt Solution</Accordion.Header>
                                        <Accordion.Body>
                                        <span className="acordyantext">WLTH selected Edimarkt based on our track record of delivering multiple successful projects
                                         for integration and data, and because of Boomi’s confidence in our capabilities. We started the discovery exercise with
                                          the leadership team to map out the WLTH platform’s integration architecture and data solutions.</span><br/>
                                        <br/>  
                                        <b className="acordyantext">Strategic advisory for Data services</b><br/>
                                        <br/>
                                        <span className="acordyantext">
                                        To complement Boomi – which was the preferred iPaaS provider from the start – the WLTH team was looking for solutions like
                                         IAM and data warehousing. After understanding the business requirements, we recommended and made introductions to Okta and
                                          Snowflake. Our partner neutrality allowed us to play the role of advisor during their technical and commercial due diligence.
                                        </span>
                                        <br/>
                                        <span className="acordyantext">For data warehousing and modelling, Edimarkt recommended and implemented VaultSpeed for
                                         its data vault automation capabilities and accelerated data warehouse deployment on the back of Data Vault 2.0 
                                         certified platform.</span><br/>
                                        <br/>    
                                        <span className="acordyantext">From discovery to delivery, the Edimarkt team delivered a data and integration solution
                                         in an unprecedented six-month period. We worked closely with WLTH leadership and technology teams and they were very
                                          proactive and agile while defining the scope and adjusting timelines during the delivery phase. As a result, the WLTH
                                           platform called Single Customer Portal (SCP, pronounced “Skip”) was successfully soft-launched in Feb 2022 with 
                                           end-to-end onboarding for early adopters.The full go-live is set for May 2022.</span><br/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>    
                    </div>    
                </div>
            </div>
            <div className="cta-section" style={{ backgroundImage: `url(${introctabanner})` }}>
                <div className="ctabackground">
                <h4>""Boomi's catalogue of big-ticket connectors is complemented by Edimarkt's fit-for-purpose<br/>
                    reference architecture, which has made the handling of the third-party systems and applications<br/>
                    that power WLTH Pay far easier to manage than we could have imagined." </h4>
                    <br/>
                    <h6><b>Dave Chapman</b></h6>
                    <h6>Chief Technology Officer, WLTH</h6>
                </div>
            </div>  
            <div className="container">
                <div className="img-with-text client_story" id="clintsto32">
                    <div className="row">
                        <div className="col abouttext">
                            <div className="site-text-content">
                                <span><h6>Edimarkt implemented Boomi's low-code cloud-native iPaaS to connect myriad systems and
                                    data across the business, including standard and biometric identity management capabilities,
                                    transactional payments platforms, operational and analytical data stores, industry critical
                                    know-your-customer (KYC) and know-your-business (KYB) providers,and customer relationship management (CRM) platforms.</h6></span>
                                <span><h6>Nathan Gower, Managing Director Australia and New Zealand at Boomi, said by honing-in
                                     on its technology stack, WLTH has put itself in the driver’s seat to make headway on its appetite
                                      for growth.</h6></span>
                                <span><h6>"With a comprehensive roadmap to transform the industry and widen its reach, WLTH cannot wait
                                    for IT to 'get there when it gets there', "said Gower. "By forming the best application and data
                                    integration foundation, the start-up has built a framework capable of supporting stronger experience
                                    for WLTH Pay users, as well as protecting its hunger for future M & As with the ease to connect new
                                    applications and data as they enter the business."</h6></span>
                                <span><h6>Chapman further added: "In seconds, Boomi can handle the load of thousands of customer transactions,
                                    and with the same speed, collate it accurately and build intelligence into our analytics engine. We’re
                                    putting the value back into value-add activities, highlighting data as the most important currency in our
                                    employees' workday. When it comes to creating better business outcomes, this extends from day-to-day customer
                                    support, right through to delivering better concierge experiences, or initiatives like our Parley for the Oceans'
                                    Australian Clean-up Efforts."</h6></span>          
                            </div>
                        </div>
                        <div className="col imgtextcont">
                            <img src={ClientStory} className="imgwithtext" alt="sitelogo"  width="600" />
                        </div>
                    </div>
                </div>
                <div className="service_highlights" id="service28">
                    <h1 className="featured_hadding">Service Highlights</h1>
                    <h3 className='featurde_sulog'>Uncover insights in disconnected systems and turn them <br/>into moments that matter</h3>
                    <div className="row">
                        <div className="ascodiyan_tubing">
                            <div className="col-8 m-auto">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Key Outcomes<br/>Business Outcomes</Accordion.Header>
                                        <Accordion.Body>
                                        <span className="acordyantext">- A state-of-art application and data integration engine that synchronises 
                                        critical business functions while building a framework capable of stronger and more integrated experiences
                                        for WLTH Pay users once the platform goes live later in 2022.</span><br/>
                                        <br/>
                                        <span className="acordyantext">- A lending and payments fintech revolutionising traditional lending by 
                                        adopting a ‘pure SaaS model’ where every core process is done in a low-cost cloud-native environment.</span><br/>
                                        <br/>
                                        <span className="acordyantext">- The lending platform is on its way to finance AU $1.2 billion worth of
                                        residential and commercial loans by the end of the FY 2022, working with Parley for the Oceans to empower
                                        and assist in cleaning Australian beaches and coastlines with every home loan that is settled.</span><br/>
                                        <br/>
                                        <span className="acordyantext">-Successful technology merger with a payments platform which is set to provide 
                                        the underlying technology infrastructure for WLTH Pay. This ensures customers can access multiple financial 
                                        products like personal and business accounts with digital wallets and virtual cards seamlessly via one platform.</span><br/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Atturra advantage</Accordion.Header>
                                        <Accordion.Body>
                                        <span className="acordyantext">WLTH selected Atturra based on our track record of delivering multiple successful projects
                                         for integration and data, and because of Boomi’s confidence in our capabilities. We started the discovery exercise with
                                          the leadership team to map out the WLTH platform’s integration architecture and data solutions.</span><br/>
                                        <br/>  
                                        <b className="acordyantext">Strategic advisory for Data services</b><br/>
                                        <br/>
                                        <span className="acordyantext">
                                        To complement Boomi – which was the preferred iPaaS provider from the start – the WLTH team was looking for solutions like
                                         IAM and data warehousing. After understanding the business requirements, we recommended and made introductions to Okta and
                                          Snowflake. Our partner neutrality allowed us to play the role of advisor during their technical and commercial due diligence.
                                        </span>
                                        <br/>
                                        <span className="acordyantext">For data warehousing and modelling, Atturra recommended and implemented VaultSpeed for
                                         its data vault automation capabilities and accelerated data warehouse deployment on the back of Data Vault 2.0 
                                         certified platform.</span><br/>
                                        <br/>    
                                        <span className="acordyantext">From discovery to delivery, the Atturra team delivered a data and integration solution
                                         in an unprecedented six-month period. We worked closely with WLTH leadership and technology teams and they were very
                                          proactive and agile while defining the scope and adjusting timelines during the delivery phase. As a result, the WLTH
                                           platform called Single Customer Portal (SCP, pronounced “Skip”) was successfully soft-launched in Feb 2022 with 
                                           end-to-end onboarding for early adopters.The full go-live is set for May 2022.</span><br/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>    
                    </div>    
                </div>
            </div>

        </div>
    );
 }

export default Withbaipag;