import React from "react";
import DataIntegr from '../img/DataIntegratbanner.webp';
import Button from 'react-bootstrap/Button';
import Introduction from '../img/Introduction.webp';
import ClientStory from '../img/ClientStory.webp';
import Accordion from 'react-bootstrap/Accordion';
import Microsoftlogo from "../img/Microsoft.webp"
import boomilogo from "../img/boomi.webp"
import siftwarelogo from "../img/siftware.webp"
import opentextlogo from "../img/opentext.webp"
import smartsheetlogo from "../img/smartsheet.webp"

import Apigeelogo from "../img/Apigeelogo.webp"
import Oktalogo from "../img/Oktalogo.webp"
import SnowflakeLogo from "../img/snowflakelogo.webp"
import solace from "../img/solace.webp"
import vaultspeedrevised from "../img/vaultspeedrevised.webp"
import introctabanner from "../img/introctabanner.webp"

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };


function dataintegration() {
    return ( 
        <div className="dataintegration" id="dataintegration96">
            <div className="bannerhome"  style={{ backgroundImage: `url(${DataIntegr})` }}>
                <div className="container">
                    <div className="bannersection">
                        <div className="bannersec_conetct">
                            <h1>Integration</h1>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="click_down_menu">
                <Button variant="link" href="#Introduction98" className="fixbuttons introduction">Introduction</Button>
                <Button variant="link" href="#service28" className="fixbuttons service_highlight">Service Highlight</Button>
                <Button variant="link" href="#benefits28" className="fixbuttons benefits">Benefits</Button>
                <Button variant="link" href="#clintlogos95" className="fixbuttons Partners">Partners</Button>
                <Button variant="link" href="#clintsto32" className="fixbuttons client_story">Client Story</Button>
            </div>  */}
            <div className="container">
                <div className="img-with-text" id="Introduction98">
                    <div className="row">
                        <div className="col imgtextcont">
                            <img src={Introduction} className="imgwithtext" alt="sitelogo"  width="600" />
                        </div>
                        <div className="col abouttext">
                            <div className="site-text-content">  
                                <h1>Confidently plan and execute your strategy and business transformation milestones</h1>
                                <span><h6>You operate in a complex, ever-changing and uncertain environment. Whether a government agency or a private corporation,
                                we enable you to navigate disruption and thrive in a fast-moving landscape.</h6></span>
                                <span><h6>Get to the heart of the business problem you need to solve through our strategic capabilities across
                                business operations analysis, business transformation, infrastructure, apps, analytics and AI technologies.</h6></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service_highlights" id="service28">
                    <h1 className="featured_hadding">Service Highlights</h1>
                    <h3 className='featurde_sulog'>Uncover insights in disconnected systems and turn them <br/>into moments that matter</h3>
                    <div className="row">
                        <div className="ascodiyan_tubing">
                            <div className="col-8 m-auto">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Connecting Disparate Data & IT Systems</Accordion.Header>
                                        <Accordion.Body>
                                        <span className="acordyantext">Improve your customer and employee experiences by making it simpler
                                         and more engaging to interact with you.</span><br/>
                                        <b className="acordyantext">1. Remove boundaries & complexities</b><br/>
                                        <span className="acordyantext">Working in siloed IT environments makes everything harder.
                                         We integrate individual business units’ disparate information and applications so you can
                                          do things easier and more efficiently.</span><br/>

                                        <b className="acordyantext">2. Reap benefits faster</b><br/>
                                        <span className="acordyantext">Get the most out of your data by creating innovative, flexible,
                                         and scalable solutions quickly and effectively.</span><br/>

                                        <b className="acordyantext">3. Seamless & safe integration</b><br/>
                                        <span className="acordyantext">You need security and speed. Our data, application,
                                         and system integration experts get you where you want to go faster,
                                          without compromising on compliance.</span><br/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Simplify Managing Your Data</Accordion.Header>
                                        <Accordion.Body>
                                        <span className="acordyantext">Untangle the complex web of data software and hardware 
                                        slowing down your digital transformation journey.</span><br/>
                                        <b className="acordyantext">1. Harmonise your data</b><br/>
                                        <span className="acordyantext">Create a 360 view of your data across structured
                                         and unstructured data sets to support your findability and analytics needs.</span><br/>

                                        <b className="acordyantext">2. Leverage high-growth technologies</b><br/>
                                        <span className="acordyantext">Your needs are unique, and so is the blend of tech you require.
                                         We have experts across various technologies to provide custom-fit solutions and deliver managed services.</span><br/>

                                        <b className="acordyantext">3. Access expertise & partnerships</b><br/>
                                        <span className="acordyantext">You can trust our expertise because we partner
                                         with the best data integration vendors in the market. Whether you need traditional
                                          or cloud-based integration, our data specialists help you more quickly realise your business outcomes.</span><br/>

                                        <b className="acordyantext">4. Confidence that comes with experience</b><br/>
                                        <span className="acordyantext">You need robust and high-performing solutions.
                                        Edimarkt's award-winning team deliver these through an architecture-driven approach,
                                          adherence to industry best practice, a focus on API management process, and our own IP.</span><br/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>    
                    </div>    
                </div>
            </div>
            <div className="benefits_section" id="benefits28">
                <div className="container">
                    <div className="row">
                        <div className="col-3 hadding_benefit">
                            <h1 className="benefits_hadding">Benefits</h1>
                        </div>
                        <div className="col-3 nenefitbox">
                            <h3>Create Connections</h3>
                            <h6>Integration brings your data and IT systems together to link business units, break down silos, and connect your people with your customer.</h6>
                        </div>
                        <div className="col-3 nenefitbox">
                            <h3>Create Meaning</h3>
                            <h6>Use the full power of your data by turning it into actionable insights across your organisation and in serving your customers.</h6>
                        </div>
                        <div className="col-3 nenefitbox">
                            <h3>Create Certainty</h3>
                            <h6>Capture value by partnering with data integration leaders in managing, monitoring, and maintaining high-integrity data.</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="clientslogo" id="clintlogos95">
                    <h1>Partners</h1>
                    <Carousel responsive={responsive}>
                        <div><img src={Microsoftlogo} className="" alt="sitelogo"  width="200px" /></div>
                        <div><img src={boomilogo} className="" alt="sitelogo"  width="200px" /></div>
                        <div><img src={opentextlogo} className="" alt="sitelogo"  width="200px" /></div>
                        <div><img src={siftwarelogo} className="" alt="sitelogo"  width="200px" /></div>
                        <div><img src={smartsheetlogo} className="" alt="sitelogo"  width="200px" /></div>
                        <div><img src={Apigeelogo} className="" alt="sitelogo"  width="200px" /></div>
                        <div><img src={Oktalogo} className="" alt="sitelogo"  width="200px" /></div>
                        <div><img src={SnowflakeLogo} className="" alt="sitelogo"  width="200px" /></div>
                        <div><img src={solace} className="" alt="sitelogo"  width="200px" /></div>
                        <div><img src={vaultspeedrevised} className="" alt="sitelogo"  width="200px" /></div>
                    </Carousel>
                </div>
                <div className="img-with-text client_story" id="clintsto32">
                    <div className="row">
                        <div className="col abouttext">
                            <div className="site-text-content">
                                <h5>Press release</h5>
                                <h1>Client Story</h1>
                                <span><h6>Enabling Energy and Utilities giant to simplify and modernise<br/>    for a brighter future.</h6></span>
                                    <Button variant="btn btn-outline-primary sitebuttonrepet">Read Client Story</Button>{' '}
                            </div>
                        </div>
                        <div className="col imgtextcont">
                            <img src={ClientStory} className="imgwithtext" alt="sitelogo"  width="600" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cta-section" style={{ backgroundImage: `url(${introctabanner})` }}>
            <div className="ctabackground">
              <h1>Looking for a step-change in your <br/> business’ technology capabilities?</h1>
              <h4>We’ll lead you there.</h4>
              <Button variant="btn btn-outline-primary learn">LEARN MORE..</Button>{' '}
            </div>
          </div>  
        </div>
    );
 }

export default dataintegration;