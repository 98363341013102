import React from "react";
import advisobanner from '../img/advisorbanne.webp';
import Button from 'react-bootstrap/Button';
import Introduction from '../img/Introduction.webp';
import ClientStory from '../img/ClientStory.webp';
import Accordion from 'react-bootstrap/Accordion';


function advisory() {
    return ( 
        <div className="advisory" id="advisory96">
            <div className="bannerhome"  style={{ backgroundImage: `url(${advisobanner})` }}>
                <div className="container">
                    <div className="bannersection">
                        <div className="bannersec_conetct">
                            <h1>Advisory-Consulting</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="click_down_menu">
                <Button variant="link" to="Introduction98" className="fixbuttons introduction">Introduction</Button>
                <Button variant="link" className="fixbuttons service_highlight">Service Highlight</Button>
                <Button variant="link" className="fixbuttons benefits">Benefits</Button>
                <Button variant="link" className="fixbuttons client_story">Client Story</Button>
            </div> 
            <div className="container">
                <div className="img-with-text" id="Introduction98">
                    <div className="row">
                        <div className="col imgtextcont">
                            <img src={Introduction} className="imgwithtext" alt="sitelogo"  width="600" />
                        </div>
                        <div className="col abouttext">
                            <div className="site-text-content">  
                                <h1>Confidently plan and execute your strategy and business transformation milestones</h1>
                                <span><h6>You operate in a complex, ever-changing and uncertain environment. Whether a government agency or a private corporation,
                                we enable you to navigate disruption and thrive in a fast-moving landscape.</h6></span>
                                <span><h6>Get to the heart of the business problem you need to solve through our strategic capabilities across
                                business operations analysis, business transformation, infrastructure, apps, analytics and AI technologies.</h6></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service_highlights">
                    <h1 className="featured_hadding">Service Highlights</h1>
                    <h3 className='featurde_sulog'>In a world full of noise, we’re an experienced and independent voice you <br/>can trust</h3>
                    <div className="row">
                        <div className="ascodiyan_tubing">
                            <div className="col-8 m-auto">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Strategic Business Transformation</Accordion.Header>
                                        <Accordion.Body>
                                        <span className="acordyantext">You're constantly facing unknowns, challenges and untapped opportunities.
                                        We guide you forward to safely innovate and transform.</span><br/>
                                        <b className="acordyantext">1. Evolve strategy & business plans</b><br/>
                                        <span className="acordyantext">Align your organisation’s vision, goals, and actions,
                                        even in highly charged situations. We help you consider factors and risks,
                                        develop measures for transformation and steps for success.</span><br/>

                                        <b className="acordyantext">2. Improve management performance</b><br/>
                                        <span className="acordyantext">More confidently make decisions that combine smart technology with hindsight, 
                                        insight and foresight. Better execute operationally and proactively manage emerging risks,
                                        complexity and scale.</span><br/>

                                        <b className="acordyantext">3. Enable enterprise governance</b><br/>
                                        <span className="acordyantext">Better control, direct or regulate organisational performance.  
                                        Working collaboratively, we provide evidence-based advice – enabling you to improve governance and effectively meet legal,
                                        strategic, community and environmental obligations.</span><br/>

                                        <b className="acordyantext">4. Hone enterprise risk management</b><br/>
                                        <span className="acordyantext">Ensure your enterprise risk management activities support executive decision-making. 
                                        Understand risk appetite and biases affecting your culture; identify and track critical controls;
                                        and build better risk processes. Gain meaningful insights through business simulation, testing, and analytics.</span><br/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Preparing For Implementation</Accordion.Header>
                                        <Accordion.Body>
                                        <span className="acordyantext">Transformational success relies strongly on building good 
                                        foundations and structures that sustain it over time.</span><br/>
                                        <b className="acordyantext">1. Framework for benefits realisation</b><br/>
                                        <span className="acordyantext">Ensure you can prioritise and identify the right
                                         investments through our benefits management framework.</span><br/>

                                        <b className="acordyantext">2. Process design</b><br/>
                                        <span className="acordyantext">Improve effectiveness through rigorously designed processes
                                        aligned to your outcomes. This includes equipping staff with guidelines,
                                        documentation and training on customer interactions and daily operations.</span><br/>

                                        <b className="acordyantext">3. People impact</b><br/>
                                        <span className="acordyantext">The best technical solutions and most efficient business
                                        processes will still fail if not supported by a prepared and engaged workforce.
                                        We design, implement and support effective change measures, ensuring successful adoption,
                                        driving value realisation and return on investment.</span><br/>

                                        <b className="acordyantext">4. Holistic approach</b><br/>
                                        <span className="acordyantext">Align your messaging and context across communications, engagement,
                                        and training. All our interventions integrate to deliver a clear story that resonates with your people
                                        to deliver high levels of adoption—helping you meet project and business objectives.</span><br/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Realise and Sustain Benefits</Accordion.Header>
                                        <Accordion.Body>
                                        <span className="acordyantext">Enable successful delivery and uptake of your
                                         transformation projects for long-term benefit.</span><br/>
                                        <b className="acordyantext">1. Deploy results-driven change</b><br/>
                                        <span className="acordyantext">To effectively implement organisation-wide transformation,
                                        you want change partners with a proven methodology. Our change approach is informed by the
                                        industry recognised Prosci ADKAR™ model, enhanced to meet the real requirements of organisations in ANZ.</span><br/>

                                        <b className="acordyantext">2. Drive successful outcomes today</b><br/>
                                        <span className="acordyantext">Overall project success depends on high levels of adoption.
                                        We manage the people element of your transformation, working as an equal partner with your
                                        technical and process teams. As the voice of your users, we provide checks and balances to
                                        ensure business and technical readiness, so your objectives are met.</span><br/>

                                        <b className="acordyantext">3. Prepare for your future success</b><br/>
                                        <span className="acordyantext">You can’t predict the future, but you can improve your
                                         ability to anticipate change. We help you bridge the gap between guesswork and readiness.
                                          Collaborating with you and futurists, facilitators and designers, we help you better understand
                                           possible opportunities and threats.</span><br/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>    
                    </div>    
                </div>
            </div>
            <div className="benefits_section">
                <div className="container">
                    <div className="row">
                        <div className="col-3 hadding_benefit">
                            <h1 className="benefits_hadding">Benefits</h1>
                        </div>
                        <div className="col-3 nenefitbox">
                            <h3>Clear path</h3>
                            <h6>For the short, medium, and long-term future of your organisation, so you can build upon a shared vision.</h6>
                        </div>
                        <div className="col-3 nenefitbox">
                            <h3>Confident decisions</h3>
                            <h6>Feel assured about the technology solutions and execution plans for your digital transformation.</h6>
                        </div>
                        <div className="col-3 nenefitbox">
                            <h3>Create value</h3>
                            <h6>For your organisation and customers. Capitalise on new opportunities created through your chosen technology</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="img-with-text client_story">
                    <div className="row">
                        <div className="col abouttext">
                            <div className="site-text-content">
                                <h5>Press release</h5>
                                <h1>Client Story</h1>
                                <span><h6>Supporting 400,000 travellers every day with a<br/> city-shaping rail infrastructure project.</h6></span>
                                    <Button variant="btn btn-outline-primary sitebuttonrepet">Read Client Story</Button>{' '}
                            </div>
                        </div>
                        <div className="col imgtextcont">
                            <img src={ClientStory} className="imgwithtext" alt="sitelogo"  width="600" />
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    );
 }

export default advisory;