import React from "react";
import Managedbanner from '../img/Managedbanner.webp';
import Button from 'react-bootstrap/Button';
import Introduction from '../img/itoperations.webp';
import ClientStory from '../img/secclintstory.webp';
import Accordion from 'react-bootstrap/Accordion';
import introctabanner from "../img/introctabannersec.webp"


function managedpage() {
    return ( 
        <div className="managedsevice" id="managedsevice96">
            <div className="bannerhome"  style={{ backgroundImage: `url(${Managedbanner})` }}>
                <div className="container">
                    <div className="bannersection">
                        <div className="bannersec_conetct">
                            <h1>Managed Services</h1>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="click_down_menu">
                <Button variant="link" href="#Introduction98" className="fixbuttons introduction">Introduction</Button>
                <Button variant="link" href="#service28" className="fixbuttons service_highlight">Service Highlight</Button>
                <Button variant="link" href="#benefits28" className="fixbuttons benefits">Benefits</Button>
                <Button variant="link" href="#clintsto32" className="fixbuttons client_story">Client Story</Button>
            </div>  */}
            <div className="container">
                <div className="img-with-text" id="Introduction98">
                    <div className="row">
                        <div className="col imgtextcont">
                            <img src={Introduction} className="imgwithtext" alt="sitelogo"  width="600" />
                        </div>
                        <div className="col abouttext">
                            <div className="site-text-content">  
                                <h1>Looking to outsource all your IT operations for peace of mind?</h1>
                                <span><h6>Managed Services takes the pain and worry away from dealing
                                     with the myriad of IT issues that get in the way of business stability and agility.</h6></span>
                                <span><h6>From resolving small and large issues to providing strategic IT services, we can help.</h6></span>
                                <span><h6>Whether you’re a small, medium or large enterprise, we offer reliable,
                                     end-to-end managed services—delivered as promised.</h6></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service_highlights" id="service28">
                    <h1 className="featured_hadding">Service Highlights</h1>
                    <h3 className='featurde_sulog'>Drive success and growth with end-to-end IT support services</h3>
                    <div className="row">
                        <div className="ascodiyan_tubing">
                            <div className="col-8 m-auto">
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Why Managed Services?</Accordion.Header>
                                        <Accordion.Body>
                                        <span className="acordyantext">Managed Services are a cost-efficient way to enable the 
                                        consistent and smooth running of your applications and infrastructure.  </span><br/>
                                        <b className="acordyantext">1. Peace of mind</b><br/>
                                        <span className="acordyantext">Your people depend on technology to support them in doing
                                         their best work. To help with this, we provide proactive and reactive support,
                                        including application and infrastructure health checks. We enhance your systems'
                                         performance and stability with effective monitoring, upgrades, and optimisation.</span><br/>

                                        <b className="acordyantext">2. Compete and grow, safely</b><br/>
                                        <span className="acordyantext">Whether improving service delivery, productivity or
                                         increasing system reliability, managed services allow you to navigate disruption
                                          more confidently or take advantage of new opportunities.</span><br/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Edimarkt’s Approach</Accordion.Header>
                                        <Accordion.Body>
                                        <span className="acordyantext">We improve your technology suite's efficiency, security,
                                         and functionality while making it easier for your team to access support. This includes:</span><br/>
                                        <b className="acordyantext">1. End-user services</b><br/>
                                        <span className="acordyantext">Enable improved confidence and enhanced productivity for 
                                        your people through Edimarkt's Service Desk. Our dedicated team resolves issues via tickets
                                         or provides phone-based support across business applications such as ERP, CRM and ECM.</span><br/>

                                        <b className="acordyantext">2. Cloud & infrastructure services</b><br/>
                                        <span className="acordyantext">Leverage cloud experts to help you create connected workflows,
                                         systems and people. We’re an award-winning Boomi partner supporting their iPaas solution,
                                          and a Microsoft Gold Partner who can deploy, integrate and support D365, Power Platform and Azure.</span><br/>

                                        <b className="acordyantext">3. Security services</b><br/>
                                        <span className="acordyantext">Keep your organisation secure while enabling greater collaboration.
                                         We help you protect your applications whether your team works at home, at the office or the airport.</span><br/>

                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Productive Partnerships Start Here</Accordion.Header>
                                        <Accordion.Body>
                                        <span className="acordyantext">Support agreements that are right for you—we work to understand your
                                         needs through our robust onboarding process.</span><br/>
                                        <b className="acordyantext">1. Collaborate & discover</b><br/>
                                        <span className="acordyantext">Based on your business or service requirements, 
                                        we look at your current tech platforms and IT network before talking through your
                                         IT pain points. We then undertake an IT audit to ensure the right-fit services.</span><br/>

                                        <b className="acordyantext">2. Recommend & deliver</b><br/>
                                        <span className="acordyantext">You have unique priorities, and we use IT best practices to
                                         identify what they are. This helps us define the services that deliver the most value to you
                                          and the agreed scope of work.</span><br/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>    
                    </div>    
                </div>
            </div>
            <div className="benefits_section" id="benefits28">
                <div className="container">
                    <div className="row">
                        <div className="col-3 hadding_benefit">
                            <h1 className="benefits_hadding">Benefits</h1>
                        </div>
                        <div className="col-3 nenefitbox">
                            <h3>On-going</h3>
                            <h6>End-to-end services tailored to your business— providing a streamlined and cost-effective solution that keeps up with pressures and demands.</h6>
                        </div>
                        <div className="col-3 nenefitbox">
                            <h3>On-board</h3>
                            <h6>We partner with you to fully understand the finer details, supporting you to drive business outcomes and growth or enable continual digital transformation.</h6>
                        </div>
                        <div className="col-3 nenefitbox">
                            <h3>On-point</h3>
                            <h6>Managed support by seasoned experts means we can help you extract more value from your technology stack.</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="img-with-text client_story" id="clintsto32">
                    <div className="row">
                        <div className="col abouttext">
                            <div className="site-text-content">
                                <h5>Press release</h5>
                                <h1>Client Story</h1>
                                <span><h6>Enabling people with Diabetes to gain better access to services,<br/> support and diabetes’ products with modern and <br/>streamlined processes.</h6></span>
                                    <Button variant="btn btn-outline-primary sitebuttonrepet">Read Client Story</Button>{' '}
                            </div>
                        </div>
                        <div className="col imgtextcont">
                            <img src={ClientStory} className="imgwithtext" alt="sitelogo"  width="600" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cta-section" style={{ backgroundImage: `url(${introctabanner})` }}>
            <div className="ctabackground">
              <h1>Looking for a step-change in your <br/> business’ technology capabilities?</h1>
              <h4>We’ll lead you there.</h4>
              <Button variant="btn btn-outline-primary learn">LEARN MORE..</Button>{' '}
            </div>
          </div>  
        </div>
    );
 }

export default managedpage;