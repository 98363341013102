import React from 'react';
import investorsimg from "../img/investors.webp";
import ivestarinfoimg from "../img/ivestarinfo.webp"
import Featuredifastimg from "../img/Featuredifastimg.webp"
import Featuredimdsec from "../img/Featuredimdsec.webp"
import Featuredimgthrd from "../img/Featuredimgthrd.webp"
import Button from 'react-bootstrap/Button';
import teamicon from "../img/team1.webp"
import budgeticon from "../img/budget.webp"
import brieficon from "../img/brief.webp"
import Bitcoinicon from "../img/Bitcoin.webp"
import Investorpresentationicon from "../img/Investorpresentation.webp"
import Sharedworkspaceicon from "../img/Sharedworkspace.webp"
import ctvbackgroundimg from "../img/ctvbackground.webp"
import Progresschart from './progresschart';





function Investors() {
    return (
      <div className="investors" id="investors987">
        <div className="investors">
            <div className="bannerhome"  style={{ backgroundImage: `url(${investorsimg})` }}>
                <div className="container">
                    <div className="bannersection">
                        <div className="bannersec_conetct">
                            <h1>Investors</h1>
                            <h3>Share Price</h3>
                            <div className="stockprice d-flex justify-content-center ">
                                <div>
                                    <h3>$0.64</h3>
                                </div>
                                <div>
                                    <h5>+0.02 / 3.2%</h5>
                                    <h6>As at 14th Jul, 7:00pm</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="img-with-text">
                <div className="row">
                <div className="col imgtextcont">
                    <img src={ivestarinfoimg} className="imgwithtext" alt="sitelogo"  width="600" />
                </div>
                <div className="col abouttext">
                    <div className="site-text-content">
                    <h5>Redefining what the future looks like through technology</h5>    
                    <h1>Chairman's Message</h1>
                    <span><h6>Edimarkt aims to be Australia's leading IT solutions provider with high engagement across industries, employees, and clients.
                         To achieve this, the Company's growth strategy is focused on building scale.</h6></span>
                        <span><h6>Edimarkt's current infrastructure will allow the Company to scale into a significantly larger business.
                             Edimarkt plans to grow by focusing on growing national presence across target industries,
                              creating new service lines, headcount growth and revenue growth from its existing client base.</h6></span>
                        <span><h6>The Board and leadership team are focused on the opportunity to significantly grow the Edimarkt business.
                             Led by CEO Stephen Kowal, his management team has significant experience in the IT services and advisory industry, 
                             and has also intimate knowledge of the various industries it operates in.</h6></span>
                        <span><h6>Edimarkt's leading position in the market together with its focus on digital transformation and 
                            the management team's substantial experience will deliver exceptional success.</h6></span>
                             <h4>Edimarkt, we’ll lead you there.</h4>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className="progresslevel">
            <div className="container">
                <div className="row">
                    <div className="col-3">
                        <div className="prin_progess">
                            <h1>23.5%</h1>
                            <h3>&nbsp;</h3>
                            <h5>revenue CAGR of 23.5% from FY19 to FY21</h5>
                        </div>
                        <h5 className="progboxname">Revenue Growth</h5>
                    </div>
                    <div className="col-3">
                        <div className="prin_progess">
                            <h1>$35</h1>
                            <h3>Billion</h3>
                            <h5>estimated IT services spend in Australia in 2021</h5>
                        </div>
                        <h5 className="progboxname">Market Size</h5>
                    </div>
                    <div className="col-6">
                        <div className="peogresschartsection">
                            <Progresschart />
                        </div>    
                    </div>
                </div>
            </div>    
        </div>
        <div className="container">
            <div className="Featured_Press_Releases">
                <h1 className="featured_hadding">Featured Press Releases</h1>
                <h3 className='featurde_sulog'>A selection of Edimarkt's latest releases and announcements.</h3>
                <div className="img-with-text">
                    <div className="row">
                        <div className="col imgtextcont">
                            <img src={Featuredifastimg} className="imgwithtext" alt="sitelogo"  width="600" />
                        </div>
                        <div className="col abouttext">
                            <div className="site-text-content">
                            <h5>Press release</h5>
                            <h1>Edimarkt Named Boomi's 2021 APJ<br/>Partner of the Year</h1>
                            <h6><span>Award received at the annual Boomi Partner Summit.
                                Third-time winner of the APJ Partner of the Year award.
                                Also received recognition as 2021 Boomi APJ Spotlight Award Winner.</span></h6>
                                <Button variant="btn btn-outline-primary sitebuttonrepet">GET IN TOUCH</Button>{' '}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="img-with-text responroted">
                    <div className="row">
                        <div className="col abouttext">
                            <div className="site-text-content">
                            <h5>Press release</h5>
                            <h1>Lion undergoes iPaaS <br/>modernisation with Edimarkt</h1>
                            <span><h6>Alcoholic beverages behemoth Lion recently<br/> commissioned an extensive iPaaS upgrade and<br/> modernisation project with Sydney-headquartered<br/> Edimarkt.</h6></span>
                                <Button variant="btn btn-outline-primary sitebuttonrepet">GET IN TOUCH</Button>{' '}
                            </div>
                        </div>
                        <div className="col imgtextcont">
                            <img src={Featuredimdsec} className="imgwithtext" alt="sitelogo"  width="600" />
                        </div>
                    </div>
                </div>
                <div className="img-with-text">
                    <div className="row">
                        <div className="col imgtextcont">
                            <img src={Featuredimgthrd} className="imgwithtext" alt="sitelogo"  width="600" />
                        </div>
                        <div className="col abouttext">
                            <div className="site-text-content">
                            <h5>Press release</h5>
                            <h1>Edimarkt wins the Smartsheet APAC <br/>Partner of the Year Award</h1>
                            <span><h6>Recognized by Smartsheet (NYSE:SMAR), the enterprise platform for dynamic work,
                                 Edimarkt continues to expand and extend their reputation as the region's #1 Platinum
                                  Partner and leading services delivery and training specialist.</h6></span>
                                <Button variant="btn btn-outline-primary sitebuttonrepet">GET IN TOUCH</Button>{' '}
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        <div className="Our_Business">
            <div className="container">
                <h1 className="featured_hadding">Our Business</h1>
                <div className="row">
                    <div className="col">
                        <div className="servivecontent">
                            <img src={teamicon} className="imgwithtext" alt="sitelogo"  width="100px" />
                            <h2>Corporate <br/>Governance</h2>
                            <p className="servicesbtn"><Button variant="outline-primary sitebuttonrepet">View Governance Documents</Button>{' '}</p>
                        </div> 
                    </div>
                    <div className="col">
                        <div className="servivecontent">
                            <img src={budgeticon} className="imgwithtext" alt="sitelogo"  width="100px" />
                            <h2>Financial Reports <br/>& Presentations</h2>
                            <p className="servicesbtn"><Button variant="outline-primary sitebuttonrepet">View Governance Documents</Button>{' '}</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="servivecontent">
                            <img src={brieficon} className="imgwithtext" alt="sitelogo"  width="100px" />
                            <h2>AGMs <br/>& Briefings</h2>
                            <p className="servicesbtn"><Button variant="outline-primary sitebuttonrepet">View Governance Documents</Button>{' '}</p>
                        </div>
                    </div>
                </div>  
            </div>    
        </div>
        <div className="container">
            <div className="Investor_Services">
                <h1 className="featured_hadding">Investor Services</h1>
                <h3 className="featurde_sulog">Please find contact details to address any investor related queries you have below.</h3>
                <div className="row">
                    <div className="col">
                        <div className="advantagelist">
                            <div className="advantagelogo">
                                <img src={Bitcoinicon} alt="sitelogo"  width="120" />
                            </div>    
                            <div className="advantagecontent">
                                <h2>My Shareholding</h2>
                                <h6>The Edimarkt share register is maintained by Computershare Investor Services Pty Ltd.</h6>
                                <h6>www.computershare.com.au</h6>
                           </div> 
                        </div>
                        <div className="advantagelist">
                            <div className="advantagelogo">
                                <img src={Investorpresentationicon} alt="sitelogo"  width="120" />
                            </div>    
                            <div className="advantagecontent">
                                <h2>Investor Contacts</h2>
                                <h6>Computershare Investor Services Pty Ltd</h6>
                                <h6>GPO Box 2975 Melbourne VIC 3001</h6>
                                <h6>1300 850 505 (within Australia) +61 3 9415 4000 (outside Australia)</h6>
                                <h6>web.queries@computershare.com.au</h6>
                           </div> 
                        </div>
                    </div>
                    <div className="col">
                        <div className="advantagelist">
                            <div className="advantagelogo">
                                <img src={Sharedworkspaceicon} alt="sitelogo"  width="120" />
                            </div>    
                            <div className="advantagecontent">
                                <h2>Investors & Media Enquiries</h2>
                                <h6>Communications +61 2 9657 0999</h6>
                                <h6>investorrelations@attura.com</h6>
                           </div> 
                        </div>
                    </div>
                </div>  
            </div>    
        </div>
        <div className="cta_section" style={{ backgroundImage: `url(${ctvbackgroundimg})` }}>
            <div className="container">
                <div className="cta_background">
                    <h1>Disclaimer</h1>
                    <span><h6>Edimarkt makes no warranty as to the accuracy, suitability or completeness of the material available. 
                    This website and the materials in this website are provided on an "as is" basis without warranties of any kind,
                     either express or implied. Edimarkt disclaims all warranties, express or implied, including, but not limited to, 
                     implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. 
                     Edimarkt does not represent or warrant that materials in this website are accurate, complete, reliable, 
                     current, or error-free.</h6></span>
                    <span><h6>Edimarkt does not represent or warrant that this website or its server(s) are free of viruses or other harmful
                     aspects. Edimarkt reserves the right to change any and all content contained on this website at any time without notice.
                      reference to any products, services, processes, or other information, by trade name, trademark, manufacturer, supplier,
                       or other designation does not constitute or imply endorsement, sponsorship, or recommendation by Edimarkt.</h6></span>
                    <span><h6>You assume full responsibility for your use of this website and any linked website.
                     If you are dissatisfied with this website or the information on this website, 
                     your sole remedy against Edimarkt is to stop using the website and the information.</h6></span>
                    <span><h6>In no event shall Edimarkt, or its offices, directors, shareholders, or employees, 
                    be liable for any direct, special, indirect, or consequential damages, or any other damages of any kind,
                     including but not limited to loss of use, loss of profits, or loss of data, whether in an action in contract, 
                     tort (including but not limited to negligence),
                      or other form, arising out of or in any way connected with the use of this website or the materials contained in, 
                      or accessed through, this website.</h6></span>
                </div>
            </div>    
        </div>
      </div>
    );
  }

export default Investors;